import {createSlice} from "@reduxjs/toolkit";

export const LoadingScreen = createSlice({
	name: "LoadingScreen",
	initialState: {
		value: true,
		firsLoad: false
	},
	reducers: {
		firstCloseReset: state => {
			state.value = true
			state.firstLoad = false
		},
		firstclose: state => {
			state.value = false
			state.firstLoad = true
		},
		close: state => {
			state.value = false
		},
		open: state => {
			state.value = true
		}
	}
})
export const {firstCloseReset, firstclose, close, open} = LoadingScreen.actions
export default LoadingScreen.reducer