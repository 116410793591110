import {useDispatch, useSelector} from "react-redux";
import Header from "panel/components/Header";
import {useHistory} from "react-router-dom";
import Aside from "panel/components/Aside";
import PanelContent from "./PanelContent";
import {open} from "stores/LoadingScreen";
import Giris from "main/pages/Giris";

function PanelPages() {
    document.title = "JoyNFC | Panel";
    let loaded = useSelector(state => state.Session.loaded);
    let auth = useSelector(state => state.Session.auth);
    const dispatch = useDispatch();
    const history = useHistory();
    history.listen((location) => {
        dispatch(open())
    })
    return (
        <>
            <div className={"PanelContainer"}>
                {
                    loaded ?
                        auth === false ?
                            <>
                                <Giris/>
                            </>
                            :
                            <>
                                {
                                    <>
                                        <Aside/>
                                        <main>
                                            <Header/>
                                            <PanelContent/>
                                        </main>
                                    </>
                                }

                            </>
                        :
                        <></>
                }
            </div>
        </>
    )
}

export default PanelPages;