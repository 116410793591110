import {createSlice} from "@reduxjs/toolkit";

export const MenuToggle = createSlice({
    name: "MenuToggle",
    initialState: {
        value: !Boolean(localStorage.getItem("menu")),
    },
    reducers: {
        toggle: state => {
            localStorage.setItem("menu", `${!state.value}`)
            state.value = !state.value
        },
        close: state => {
            state.value = false
        },
        open: state => {
            state.value = true
        }
    }
})
export const {toggle, close, open} = MenuToggle.actions
export default MenuToggle.reducer