import {useStateIfMounted} from "use-state-if-mounted";
import {isMobile} from 'react-device-detect';
import {useHistory} from "react-router-dom";
import {Col, Row} from "react-grid-system";
import {useSelector} from "react-redux";
import Select from "components/Select";
import React, {useEffect} from "react";
import Button from "components/Button";
import serialize from "form-serialize";
import Input from "components/Input";
import functions from "functions";
import axios from "axios";

function User({id}) {
	let language = useSelector(state => state.Language.language);
	const [loading, setLoading] = useStateIfMounted(false);
	const [user, setUser] = useStateIfMounted("");
	const history = useHistory();
	useEffect(() => {
		async function uye() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get/user/${id}`)
			await setUser(response.data.result)
		}

		void uye()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	async function edit(event) {
		event.preventDefault()
		setLoading(true)
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/edit/user/${id}`, serialize(event.target));
		response = response.data
		if (response.ok) {
			await functions.message("success", language["process_successful"], language[response.ok], false, "", true, "top-end", 1500)
			setTimeout(async function () {
				history.push("/panel/users")
				setLoading(false)
			}, 1500);
		} else if (response.error) {
			await functions.message("error", language["process_failed"], language[response.error], true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		} else {
			await functions.message("error", language["process_failed"], "", true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		}
	}

	const types = [
		{
			label: language["user_type0"],
			value: "0"
		},
		{
			label: language["user_type1"],
			value: "1"
		},
		{
			label: language["user_type2"],
			value: "2"
		}
	]
	return (
		<div>
			<div className="panelTitle">{language["edit_user_page_title"]}</div>
			<div className="wrapper">
				<form onSubmit={edit}>
					<Row>
						<Input type={"email"} name={"email"} col={6} icon={"BiAt"} title={language["email_input_title"]} placeholder={language["email_input_placeholder"]} value={user.email} forced={true}/>
						<Input type={"password"} name={"password"} col={6} icon={"MdOutlinePassword"} title={language["password_input_title"]} placeholder={language["password_input_placeholder"]}/>
						<Input type={"number"} name={"card_limit"} col={6} icon={"AiOutlineFieldNumber"} title={language["card_limit_input_title"]} placeholder={language["card_limit_input_placeholder"]} value={user.card_limit} forced={true}/>
						<Select title={language["user_type_input_title"]} icon={"AiOutlineStar"} col={6} name={"type"} defaultValue={isMobile ? user.type : types[user.type]} values={types} forced={true} placeholder={language["user_type_input_placeholder"]}/>
						<Col md={12} className="button_area">
							<Button icon="AiOutlineSave" name={language["save_button"]} loading={loading}/>
						</Col>
					</Row>
				</form>
			</div>
		</div>
	)
}

export default User