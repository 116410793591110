import {useStateIfMounted} from "use-state-if-mounted";
import {Route, useHistory} from "react-router-dom";
import HomeContent from "components/HomeContent";
import {open} from "stores/LoadingScreen";
import {useDispatch} from "react-redux";
import Card from "main/pages/Card";
import {useEffect} from "react";

function HomePages() {
	const [path, setPath] = useStateIfMounted("");
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		setPath(window.location.pathname.split("/"))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	history.listen((location) => {
		dispatch(open())
	})

	const blackList = [
		"",
		"giris",
	]
	return (
		<div className={"HomeContainer"}>

			{
				blackList.indexOf(path[1]) !== -1 ?
					<HomeContent/>
					:
					<Route path="/:url" component={Card}/>
			}
		</div>
	)
}

export default HomePages;