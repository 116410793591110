import {useStateIfMounted} from "use-state-if-mounted";
import {useDispatch, useSelector} from "react-redux";
import {firstCloseReset} from "stores/LoadingScreen";
import Icons from "components/Icons";
import functions from "functions";
import axios from "axios";

function Move({editMode, color, direction, reload, comp}) {

	let language = useSelector(state => state.Language.language);
	const [loading, setLoading] = useStateIfMounted(false);
	let dispatch = useDispatch();

	async function componentMove() {
		setLoading(true);
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/operation/move/${comp.component_id}`, `direction=${direction}`);
		response = response.data
		if (response.ok) {
			await functions.message("success", language["process_successful"], language[response.ok], false, "", true, "top-end", 1500)
			setLoading(false);
			dispatch(firstCloseReset())
			reload()
		} else if (response.error) {
			await functions.message("error", language["process_failed"], language[response.error], true, language["process_failed_close_button"], false, "center", 1000)
			setLoading(false);
		}
	}

	return (
		editMode &&
		<div className={"move"}>
			{
				loading && <div className="loading"><img src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_IMAGE_PATH}/loading-buffering.gif`} alt=""/></div>
			}
			<button onClick={componentMove} style={{color: color}}><Icons name={direction === 0 ? "BsArrowBarDown" : direction === 1 ? "BsArrowBarUp" : ""}/></button>
		</div>
	)
}

export default Move;