import Language from "panel/addPages/Language";
import {useParams} from "react-router-dom";
import User from "panel/addPages/User";
import Icon from "panel/addPages/Icon";
import React from "react";

function Add() {
	let {yer} = useParams();

	return (
		<>
			{yer === "language" && <Language/>}
			{yer === "user" && <User/>}
			{yer === "icon" && <Icon/>}
		</>

	)
}

export default Add;