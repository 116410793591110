import {useStateIfMounted} from "use-state-if-mounted";
import {useHistory} from "react-router-dom";
import {Col, Row} from "react-grid-system";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import Button from "components/Button";
import serialize from "form-serialize";
import Input from "components/Input";
import functions from "functions";
import axios from "axios";

function Language({id}) {
	const [loading, setLoading] = useStateIfMounted(false);
	const [language, setLanguage] = useStateIfMounted("");
	let languageSource = useSelector(state => state.Language.language);

	const history = useHistory();
	useEffect(() => {
		async function getLanguage() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get/language/${id}`)
			await setLanguage(response.data.result)
		}

		void getLanguage()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	async function edit(event) {
		event.preventDefault()
		setLoading(true)
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/edit/language/${language.id}`, serialize(event.target));
		response = response.data
		if (response.ok) {
			await functions.message("success", languageSource["process_successful"], languageSource[response.ok], false, "", true, "top-end", 1500)
			setTimeout(async function () {
				history.push("/panel/languages")
				setLoading(false)
			}, 1500);
		} else if (response.error) {
			await functions.message("error", languageSource["process_failed"], languageSource[response.error], true,  languageSource["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		} else {
			await functions.message("error", languageSource["process_failed"], "", true, languageSource["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		}
	}

	return (
		<div>
			<div className="panelTitle">{languageSource["edit_language_page_title"]}</div>
			<div className="wrapper">
				<form onSubmit={edit}>
					<Row>
						<Input type={"text"} name={"name"} col={6} icon={"FaSignature"} title={languageSource["language_name_input_title"]} placeholder={languageSource["language_name_input_placeholder"]} value={language.name} forced={true}/>
						<Input type={"text"} name={"code"} col={6} icon={"FaLanguage"} title={languageSource["language_code_input_title"]} placeholder={languageSource["language_code_input_placeholder"]} value={language.value}/>
						<Col md={12} className="button_area">
							<Button icon="AiOutlineSave" name={languageSource["save_button"]} loading={loading}/>
						</Col>
					</Row>
				</form>
			</div>
		</div>
	)
}

export default Language