import QRCode from 'react-qr-code';

function QrCode({value, close}) {
    return (
        <div className={"qrMenuWrapper"}>
            <div onClick={close} className={"qrMenuCloseBack"}/>
            <div className={"qrMenu"}>
                <div className={"qrCode"}>
                    <QRCode value={value} size={256}/>
                    <div className={"qrCodeText"}>{value}</div>
                </div>
            </div>
        </div>
    )
}

export default QrCode;