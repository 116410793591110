import {clickAndHoverDropdown} from "dropdown-menu-basic";
import {useStateIfMounted} from "use-state-if-mounted";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {change} from "stores/Language";
import Select from "components/Select";
import React, {useEffect} from "react";
import {Link} from 'react-router-dom';
import {logout} from "stores/Session";
import functions from "functions";
import Swal from "sweetalert2";
import axios from "axios";

function Header() {
	const [languageList, setLanguageList] = useStateIfMounted([]);
	let language = useSelector(state => state.Language.language);
	const [languages, setLanguages] = useStateIfMounted([]);
	let code = useSelector(state => state.Language.value);
	const [user, setUser] = useStateIfMounted([]);
	let id = useSelector(state => state.Session.id);
	let dispatch = useDispatch();
	let history = useHistory();

	async function getLanguageSource(code) {
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get/languageSource`, `language=${code}`)
		return response.data
	}

	useEffect(() => {
		async function getLanguages() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/list/languages`)
			setLanguages(response.data.result)
			setLanguageList(response.data.list)
		}

		void getLanguageSource()
		void getLanguages()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		async function getUser() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get/user/${id}`)
			await setUser(response.data.result)
		}

		void getUser()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);


	function cikis() {
		Swal.fire({
			title: language["logout_title"], text: language["logout_text"], icon: 'question', showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: language["logout_confirm"], cancelButtonText: language["logout_cancel"]
		}).then((result) => {
			if (result.value) {
				void axios.get(`${process.env.REACT_APP_API_URL}/api/operation/logout`)
				void functions.message("success", language["process_successful"], language["logout_successful"], false, "", true, "top-end", 1500)
				setTimeout(function () {
					dispatch(logout())
					history.push("/")
				}, 1500);
			}
		})
	}

	clickAndHoverDropdown()

	return (<>

		<header className={"mainHeader"}>
			{
				user.type >= "1" &&
				<Link to={"/panel"}>Panel</Link>
			}
			<Select col={4} values={languages} title={""} icon={"MdOutlineLanguage"} defaultValue={{value: code, label: languageList[code]}} onchange={async (e) => {
				dispatch(change(await getLanguageSource(e)))
			}} searchable={true}/>
			<button onClick={() => cikis()}>{language["logout_button"]}</button>
		</header>
	</>)
}

export default Header;