import Language from "panel/editPages/Language";
import {useParams} from "react-router-dom";
import User from "panel/editPages/User";
import Icon from "panel/editPages/Icon";
import React from "react";

 function Edit() {
	let {id, yer} = useParams();

	return (
		<>
			{yer === "user" && <User id={id}/>}
			{yer === "language" && <Language id={id}/>}
			{yer === "icon" && <Icon id={id}/>}
		</>
	)
}
export default Edit;