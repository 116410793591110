import {Route, Switch} from "react-router-dom";
import Kartlarim from "main/pages/Kartlarim";
import Header from "main/components/Header";
import {useSelector} from "react-redux";
import Giris from "main/pages/Giris";

function HomeContent() {
	let auth = useSelector(state => state.Session.auth);

	return (
		<div className={"HomeContent"}>
			{
				auth &&
				<Header/>
			}
			<Switch>
				{
					auth ?
						<Route path={"/"} exact component={Kartlarim}/> :
						<Route path={"/"} exact component={Giris}/>
				}
			</Switch>
		</div>
	)
}

export default HomeContent;