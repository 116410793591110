import Icons from "components/Icons";

function InfoButton({src, editMode, editMenu}) {
    return (
        <>
            <div className="button">
                {
                    editMode ?
                        <button onClick={() => editMenu(src)} style={{backgroundColor: src.bg_color, color: src.color}}><Icons name={src.icon ?? ""}/></button>
                        :
                        <a target={"_blank"} rel={"noreferrer"} href={src.url ?? ""} style={{backgroundColor: src.bg_color, color: src.color}}><Icons name={src.icon ?? ""}/></a>
                }
            </div>
        </>
    )
}

export default InfoButton;