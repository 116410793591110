import Move from "main/components/Move";
import Icons from "components/Icons";
import QRCode from 'react-qr-code';

function WifiCode({comp,cardColor, editMode, editMenu, reload}) {
    return (
        <>
            <Move editMode={editMode} color={cardColor} direction={1} comp={comp} reload={reload}/>
            <div className={"wifiQrCode"} style={{backgroundColor: comp.component_bg}}>
                {
                    editMode &&
                    <div onClick={editMenu} className={"editArea"}>
                        <Icons name={"MdEdit"}/>
                    </div>
                }
                {
                    comp.component_title &&
                    <h2 style={{color: comp.component_color}}>{comp.component_title}</h2>
                }
                <div className="code">
                    <QRCode value={comp.component_url} size={256}/>
                </div>
                {
                    comp.component_text1 === "false" &&
                    <>
                        <b style={{color: comp.component_color}}>{comp.component_text2}</b>
                        <p style={{color: comp.component_color}}>{comp.component_url.split(";")[2].split(":")[1]}</p>

                    </>
                }
            </div>
            <Move editMode={editMode} color={cardColor} direction={0} comp={comp} reload={reload}/>
        </>
    )
}

export default WifiCode;