import {useStateIfMounted} from "use-state-if-mounted";
import {Route, Switch} from "react-router-dom";
import Languages from "panel/pages/Languages";
import {open, close} from "stores/MenuToggle";
import Language from "panel/pages/Language";
import AllIcons from "panel/pages/AllIcons";
import {useDispatch} from "react-redux";
import Users from "panel/pages/Users";
import User from "panel/pages/User";
import Edit from "panel/pages/Edit";
import Main from "panel/pages/Main";
import Add from "panel/pages/Add";
import {useEffect} from "react";

function PanelContent() {
	const [windowWidth, setWindowWidth] = useStateIfMounted(window.innerWidth);
	const [control, setControl] = useStateIfMounted(false);
	const [first, setFirst] = useStateIfMounted(false);
	const dispatch = useDispatch();
	useEffect(() => {
		if (windowWidth < 1000) {
			dispatch(close())
			setControl(false)
		} else {
			if (localStorage.getItem("menu") === "true" || localStorage.getItem("menu") === null) {
				dispatch(open())
				setControl(true)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		if (first) {
			if (windowWidth < 1000) {
				if (control === true) {
					setControl(false)
					dispatch(close())
				}
			} else {
				dispatch(open())
				setControl(true)
			}
		} else {
			setFirst(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowWidth])
	window.addEventListener("resize", () => {
		setWindowWidth(window.innerWidth)
	})
	return (
		<div className={"content"}>
			<Switch>
				<Route exact path="/panel" component={Main}/>
				<Route path="/panel/users" component={Users}/>
				<Route path="/panel/user/:id" component={User}/>
				<Route path="/panel/icons" component={AllIcons}/>
				<Route path="/panel/language/:lang" component={Language}/>
				<Route path="/panel/languages" component={Languages}/>
				<Route path="/panel/add/:yer" component={Add}/>
				<Route path="/panel/edit/:yer/:id" component={Edit}/>
			</Switch>
		</div>
	)
}

export default PanelContent;