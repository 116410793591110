import {Route, Switch, BrowserRouter} from "react-router-dom";
import {close, firstclose} from "stores/LoadingScreen";
import {useDispatch, useSelector} from "react-redux";
import LoadingScreen from "components/LoadingScreen";
import PanelPages from "components/PanelPages";
import HomePages from "components/HomePages";
import {login, load} from "stores/Session";
import {change} from "stores/Language";
import {useEffect} from "react";
import axios from "axios";

function App() {
	let firstLoad = useSelector(state => state.LoadingScreen.firstLoad);
	let loadingScreen = useSelector(state => state.LoadingScreen.value);
	let language = useSelector(state => state.Language.language);
	let type = useSelector(state => state.Session.type);
	let auth = useSelector(state => state.Session.auth);
	let dispatch = useDispatch();

	useEffect(() => {
		async function autoLogin() {
			let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/operation/autoLogin`, `token=${localStorage.getItem("token")}`);
			response = response.data
			if (response.ok) {
				dispatch(login(response))
			} else if (response.error) {
				dispatch(load())
			}
		}

		async function setLanguage() {
			let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get/languageSource`, `language=${localStorage.getItem("language") || "tr"}`);
			response = response.data
			if (response.language) {
				dispatch(change(response))
			}
		}

		void autoLogin()
		void setLanguage()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	window.addEventListener("load", () => {
		if (language) {
			dispatch(firstclose())
		}
	})

	useEffect(() => {
		if (firstLoad === true) {
			setTimeout(async function () {
				await dispatch(close())
			}, 500);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingScreen])

	return (

		<>
			{
				<LoadingScreen active={loadingScreen}/>
			}
			<BrowserRouter>
				<Switch>
					{
						(type >= "1" || type >= 1 || !auth) &&
						<Route path="/panel" component={PanelPages}/>
					}
					<Route path="/" component={HomePages}/>
				</Switch>
			</BrowserRouter>
		</>
	);
}

export default App;
