function slide(selector, direction, duration) {
    let element = document.querySelector(selector);
    if (direction === "up") {
        element.style.display = "block";
        let height = element.offsetHeight;
        let i = 0;

        let interval = setInterval(() => {
            if (i < height) {
                if ((i += height / duration) < height && (i += height / duration) >= 0) {
                    element.style.height = (height - i) + "px";
                } else {
                    element.style.height = 0;
                }
            } else {
                clearInterval(interval);
            }
            i += height / duration;
        }, 1);
    } else if (direction === "down") {
        element.style.height = null
        element.style.display = "block";
        let height = element.offsetHeight;
        element.style.height = 0;
        let i = 0;
        let interval = setInterval(() => {
            if (i < height) {
                if ((i += height / duration) < height) {
                    element.style.height = i + "px";
                } else {
                    element.style.height = height + "px";
                }
            } else {
                clearInterval(interval);
            }
            i += height / duration;
        }, 1);
    }
}

export default function Slide(selector, direction, duration) {
    slide(selector, direction, duration)
    return (
        <></>
    )
}