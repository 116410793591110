import {useStateIfMounted} from "use-state-if-mounted";
import {Row, Col} from "react-grid-system";
import {useSelector} from "react-redux";
import serialize from "form-serialize";
import Button from "components/Button";
import Select from "components/Select";
import Input from "components/Input";
import functions from "functions";
import {useEffect} from "react";
import axios from "axios";

function EditMenu({close, type, cardURL, componentID = 0, buttonSRC, source, reload}) {
	let language = useSelector(state => state.Language.language);
	const [defSetted, setDefSetted] = useStateIfMounted(false);
	const [defButton, setDefButton] = useStateIfMounted([]);
	const [loading, setLoading] = useStateIfMounted(false);
	const [security, setSecurity] = useStateIfMounted("");
	const [password, setPassword] = useStateIfMounted("");
	const [buttons, setButtons] = useStateIfMounted([]);
	const [hidden, setHidden] = useStateIfMounted("");
	const [button, setButton] = useStateIfMounted([]);
	const [SSID, setSSID] = useStateIfMounted("");
	const [show, setShow] = useStateIfMounted("");
	const [icon, setIcon] = useStateIfMounted("");
	const [card, setCard] = useStateIfMounted([]);
	useEffect(() => {
		if (type === 10) {
			async function getButtons() {
				let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/list/buttons`)
				await setButtons(response.data.result)
			}

			void getButtons()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [componentID, type]);
	useEffect(() => {
		async function getCard() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get/card/${cardURL}`)
			await setCard(response.data.result)
		}

		void getCard()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardURL]);
	useEffect(() => {
		if (type === 10) {
			async function getButton() {
				let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get/button/${icon}`)
				await setButton(response.data.result)
				if (defSetted) {
					await setDefButton(response.data.result)
				}
			}

			void getButton()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [icon, type]);
	useEffect(() => {
		if (type === 10) {
			async function getDefaultButton() {
				let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get/button/${buttonSRC.icon}`)
				await setDefButton(response.data.result)
				await setDefSetted(true)
			}

			void getDefaultButton()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [buttonSRC, type]);
	useEffect(() => {
		if (type === 4) {
			//source.component_url.split(";")[0].split(":")[2]
			setSSID(source.component_text2)
			setSecurity(source.component_url.split(";")[1].split(":")[1])
			setPassword(source.component_url.split(";")[2].split(":")[1])
			setHidden(source.component_url.split(";")[3].split(":")[1])
			setShow(source.component_text1)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [source]);

	async function editComponent(event) {
		event.preventDefault()
		setLoading(true)
		let formData = new FormData(event.target)
		formData.append("component_type", type)
		let response = await axios({
			method: "POST", url: `${process.env.REACT_APP_API_URL}/api/edit/component/${componentID}`, data: formData, headers: {
				"Content-Type": "multipart/form-data"
			}
		})
		response = response.data
		if (response.ok) {
			await functions.message("success", language["process_successful"], language[response.ok], false, "", true, "top-end", 1500)
			setLoading(false)
			close()
			reload()
		} else if (response.error) {
			await functions.message("error", language["process_failed"], language[response.error], true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		} else {
			await functions.message("error", language["process_failed"], "", true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		}

	}

	async function editButton(event) {
		event.preventDefault()
		setLoading(true)
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/edit/button/${buttonSRC.button_id}`, serialize(event.target));
		response = response.data
		if (response.ok) {
			await functions.message("success", language["process_successful"], language[response.ok], false, "", true, "top-end", 1500)
			setLoading(false)
			close()
			reload()
		} else if (response.error) {
			await functions.message("error", language["process_failed"], language[response.error], true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		} else {
			await functions.message("error", language["process_failed"], "", true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		}

	}

	async function editCard(event) {
		event.preventDefault()
		setLoading(true)
		let formData = new FormData(event.target)
		formData.append("component_type", type)
		let response = await axios({
			method: "POST", url: `${process.env.REACT_APP_API_URL}/api/edit/card/${cardURL}`, data: formData, headers: {
				"Content-Type": "multipart/form-data"
			}
		})
		response = response.data
		if (response.ok) {
			await functions.message("success", language["process_successful"], language[response.ok], false, "", true, "top-end", 1500)
			setLoading(false)
			close()
			reload()
		} else if (response.error) {
			await functions.message("error", language["process_failed"], language[response.error], true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		} else {
			await functions.message("error", language["process_failed"], "", true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		}

	}

	const wifiHid = [{
		label: language["visible"], value: "false"
	}, {
		label: language["invisible"], value: "true"
	}]

	return (<>
			<div className={"MenuWrapper"}>
				<div className={"MenuCloseBack"} onClick={close}/>
				<div className={"Menu"}>
					{type === 0 ? <>
						<h2>{language["edit_menu_title"]}</h2>
						<form onSubmit={editComponent}>
							<Row>
								<Input col={12} title={language["component_type0_title_input_title"]} name={"component_title"} icon={"FaSignature"} placeholder={language["component_type0_title_input_placeholder"]} value={source.component_title}/>
								<Input col={6} title={language["edit_menu_background_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"component_bg"} value={source.component_bg ?? "#FFFFFF"} forced={true}/>
								<Input col={6} title={language["edit_menu_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"component_color"} value={source.component_color ?? "#FFFFFF"} forced={true}/>
							</Row>
							<Row>
								<Col md={12}>
									<div className="button_area">
										<Button onclick={() => functions.yesno(componentID, language["delete_component_menu_title"], language["delete_component_menu_text"], "delete", "component", reload, language, close)} type={"button"} name={language["edit_menu_delete_button"]} icon={"BiTrash"}/>
										<Button onclick={close} type={"button"} name={language["edit_menu_cancel_button"]} icon={"ImCancelCircle"}/>
										<Button name={language["edit_menu_save_button"]} icon={"FaSave"} loading={loading}/>
									</div>
								</Col>
							</Row>
						</form>
					</> : type === 10 ? <>
						<h2>Buton Düzenle</h2>
						<form onSubmit={editButton}>
							<Row>
								<Input col={12} title={"Link"} name={"url"} icon={"AiOutlineLink"} placeholder={"Lütfen link giriniz."} value={buttonSRC.url} forced={true}/>
								<Select col={12} title={"İcon"} name={"icon"} icon={"FaIcons"} forced={true} placeholder={"İcon seçiniz."} defaultValue={{label: defButton.name, value: defButton.icon}} modern={true} values={buttons} searchable={true} clearable={true} onchange={setIcon}/>
								<Input col={6} title={"Arka Plan Rengi"} type={"color"} icon={"BsFillPaletteFill"} name={"bg_color"} value={button.bg_color ?? buttonSRC.bg_color ?? "#222222"} forced={true}/>
								<Input col={6} title={"İcon Rengi"} type={"color"} icon={"BsFillPaletteFill"} name={"color"} value={button.color ?? buttonSRC.color ?? "#FFFFFF"} forced={true}/>
							</Row>
							<Row>
								<Col md={12}>
									<div className="button_area">
										<Button onclick={() => functions.yesno(buttonSRC.button_id, "Butonu Sil", "Butonu silmek istediğinize emin misiniz?", "delete", "button", reload, language, close)} type={"button"} name={"Butonu Sil"} icon={"BiTrash"}/>
										<Button onclick={close} type={"button"} name={"Vazgeç"} icon={"ImCancelCircle"}/>
										<Button name={"Kaydet"} icon={"FaSave"} loading={loading}/>
									</div>
								</Col>
							</Row>
						</form>
					</> : type === 1 ? <>
						<h2>{language["edit_menu_title"]}</h2>
						<form onSubmit={editComponent}>
							<Row>
								<Input col={12} title={language["component_type1_title_input_title"]} type={"text"} name={"component_title"} icon={"FaSignature"} value={source.component_title} placeholder={language["component_type1_title_input_placeholder"]}/>
								<Input col={12} title={language["component_type1_image_input_title"]} icon={"BsFillImageFill"} type={"file"} name={"image"} accept={"image/*"}/>
								<Input col={12} title={language["component_type1_text1_input_title"]} type={"textarea"} name={"component_text1"} icon={"BsInfoLg"} value={source.component_text1} placeholder={language["component_type1_text1_input_placeholder"]}/>
								<Input col={12} title={language["component_type1_text2_input_title"]} type={"text"} name={"component_text2"} icon={"FaSignature"} value={source.component_text2} placeholder={language["component_type1_text2_input_placeholder"]}/>
								<Input col={6} title={language["edit_menu_background_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"component_bg"} value={source.component_bg ?? "#FFFFFF"} forced={true}/>
								<Input col={6} title={language["edit_menu_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"component_color"} value={source.component_color ?? "#FFFFFF"} forced={true}/>
							</Row>
							<Row>
								<Col md={12}>
									<div className="button_area">
										<Button onclick={() => functions.yesno(componentID, language["delete_component_menu_title"], language["delete_component_menu_text"], "delete", "component", reload, language, close)} type={"button"} name={language["edit_menu_delete_button"]} icon={"BiTrash"}/>
										<Button onclick={close} type={"button"} name={language["edit_menu_cancel_button"]} icon={"ImCancelCircle"}/>
										<Button name={language["edit_menu_save_button"]} icon={"FaSave"} loading={loading}/>
									</div>
								</Col>
							</Row>
						</form>
					</> : type === 2 ? <>
						<h2>{language["edit_menu_title"]}</h2>
						<form onSubmit={editComponent}>
							<Row>
								<Input col={12} title={language["component_type2_url_input_title"]} icon={"AiOutlineLink"} type={"text"} name={"component_url"} value={source.component_url} placeholder={language["component_type2_url_input_placeholder"]} forced={true}/>
								<Input col={12} title={language["component_type2_title_input_title"]} type={"text"} name={"component_title"} icon={"FaSignature"} value={source.component_title} placeholder={language["component_type2_title_input_placeholder"]}/>
								<Input col={12} title={language["component_type2_text1_input_title"]} type={"textarea"} name={"component_text1"} icon={"BsInfoLg"} value={source.component_text1} placeholder={language["component_type2_text1_input_placeholder"]}/>
								<Input col={12} title={language["component_type2_text2_input_title"]} type={"text"} name={"component_text2"} icon={"FaSignature"} value={source.component_text2} placeholder={language["component_type2_text2_input_placeholder"]}/>
								<Input col={6} title={language["edit_menu_background_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"component_bg"} value={source.component_bg ?? "#FFFFFF"} forced={true}/>
								<Input col={6} title={language["edit_menu_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"component_color"} value={source.component_color ?? "#FFFFFF"} forced={true}/>
							</Row>
							<Row>
								<Col md={12}>
									<div className="button_area">
										<Button onclick={() => functions.yesno(componentID, language["delete_component_menu_title"], language["delete_component_menu_text"], "delete", "component", reload, language, close)} type={"button"} name={language["edit_menu_delete_button"]} icon={"BiTrash"}/>
										<Button onclick={close} type={"button"} name={language["edit_menu_cancel_button"]} icon={"ImCancelCircle"}/>
										<Button name={language["edit_menu_save_button"]} icon={"FaSave"} loading={loading}/>
									</div>
								</Col>
							</Row>
						</form>
					</> : type === 3 ? <>
						<h2>{language["edit_menu_title"]}</h2>
						<form onSubmit={editComponent}>
							<Row>
								<Input col={12} title={language["component_type3_url_input_title"]} icon={"AiOutlineLink"} type={"text"} name={"component_url"} value={source.component_url} placeholder={language["component_type3_url_input_placeholder"]} forced={true}/>
							</Row>
							<Row>
								<Col md={12}>
									<div className="button_area">
										<Button onclick={() => functions.yesno(componentID, language["delete_component_menu_title"], language["delete_component_menu_text"], "delete", "component", reload, language, close)} type={"button"} name={language["edit_menu_delete_button"]} icon={"BiTrash"}/>
										<Button onclick={close} type={"button"} name={language["edit_menu_cancel_button"]} icon={"ImCancelCircle"}/>
										<Button name={language["edit_menu_save_button"]} icon={"FaSave"} loading={loading}/>
									</div>
								</Col>
							</Row>
						</form>
					</> : type === 4 ? <>
						<h2>{language["edit_menu_title"]}</h2>
						<form onSubmit={editComponent}>
							<Row>
								<Input col={12} title={language["component_type4_title_input_title"]} type={"text"} name={"component_title"} icon={"FaSignature"} value={source.component_title} placeholder={language["component_type4_title_input_placeholder"]}/>
								<Input col={12} title={language["component_type4_network_name_input_title"]} icon={"AiOutlineLink"} type={"text"} name={"wifi_ssid"} value={SSID} placeholder={language["component_type4_network_name_input_placeholder"]} forced={true}/>

								<Col md={12}>
									<div className={"security"}>
										<h2>{language["component_type4_network_security_input_title"]}</h2>
										<section>
											<button type={"button"} onClick={() => setSecurity("WEP")} className={security === "WEP" ? "active" : ""}>WEP</button>
											<button type={"button"} onClick={() => setSecurity("WPA")} className={security === "WPA" ? "active" : ""}>WPA/WPA2-PSK</button>
											<button type={"button"} onClick={() => setSecurity("")} className={security === "" ? "active" : ""}>{language["no_security"]}</button>
										</section>
									</div>
								</Col>
								<Input type={"hidden"} name={"wifi_security"} value={security}/>
								<Input col={12} title={language["component_type4_password_input_title"]} type={"password"} name={"wifi_password"} icon={"FaSignature"} value={password} placeholder={language["component_type4_password_input_placeholder"]}/>
								<Select col={12} title={language["component_type4_network_visibility_input_title"]} icon={"CgComponents"} name={"wifi_hidden"} placeholder={language["component_type4_network_visibility_input_placeholder"]} values={wifiHid} defaultValue={wifiHid[hidden === "true" ? 1 : 0]} forced={true}/>
								<Select col={12} title={language["component_type4_password_visibility_input_title"]} icon={"CgComponents"} name={"component_text1"} placeholder={language["component_type4_password_visibility_input_placeholder"]} values={wifiHid} defaultValue={wifiHid[show === "true" ? 1 : 0]} forced={true}/>
								<Input col={6} title={language["edit_menu_background_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"component_bg"} value={source.component_bg ?? "#FFFFFF"} forced={true}/>
								<Input col={6} title={language["edit_menu_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"component_color"} value={source.component_color ?? "#FFFFFF"} forced={true}/>
							</Row>
							<Row>
								<Col md={12}>
									<div className="button_area">
										<Button onclick={() => functions.yesno(componentID, language["delete_component_menu_title"], language["delete_component_menu_text"], "delete", "component", reload, language, close)} type={"button"} name={language["edit_menu_delete_button"]} icon={"BiTrash"}/>
										<Button onclick={close} type={"button"} name={language["edit_menu_cancel_button"]} icon={"ImCancelCircle"}/>
										<Button name={language["edit_menu_save_button"]} icon={"FaSave"} loading={loading}/>
									</div>
								</Col>
							</Row>
						</form>
					</> : type === 5 ? <>
						<h2>{language["edit_menu_title"]}</h2>
						<form onSubmit={editComponent}>
							<Row>
								<Input col={12} title={language["component_type5_title_input_title"]} type={"text"} name={"component_title"} icon={"FaSignature"} placeholder={language["component_type5_title_input_placeholder"]} value={source.component_title}/>
								<Input col={6} title={language["component_type5_button_background_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"button_bg"} value={source.component_text1} forced={true}/>
								<Input col={6} title={language["component_type5_button_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"button_color"} value={source.component_text2} forced={true}/>
								<Input col={6} title={language["edit_menu_background_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"component_bg"} value={source.component_bg ?? "#FFFFFF"} forced={true}/>
								<Input col={6} title={language["edit_menu_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"component_color"} value={source.component_color ?? "#FFFFFF"} forced={true}/>
							</Row>
							<Row>
								<Col md={12}>
									<div className="button_area">
										<Button onclick={() => functions.yesno(componentID, language["delete_component_menu_title"], language["delete_component_menu_text"], "delete", "component", reload, language, close)} type={"button"} name={language["edit_menu_delete_button"]} icon={"BiTrash"}/>
										<Button onclick={close} type={"button"} name={language["edit_menu_cancel_button"]} icon={"ImCancelCircle"}/>
										<Button name={language["edit_menu_save_button"]} icon={"FaSave"} loading={loading}/>
									</div>
								</Col>
							</Row>
						</form>
					</> : type === 100 ? <>
						<form onSubmit={editCard}>
							<Row>
								<Input col={12} title={language["company_background_image_input_title"]} icon={"BsFillImageFill"} type={"file"} name={"banner_image"} accept={"image/*"}/>
								<Input col={12} title={language["company_image_input_title"]} icon={"IoMdImage"} type={"file"} name={"company_logo"} accept={"image/*"}/>
								<Input col={12} title={language["company_color_input_title"]} icon={"BsFillPaletteFill"} type={"color"} name={"banner_color"} value={source.banner_color ?? "#FFFFFF"} forced={true}/>
							</Row>
							<Row>
								<Col md={12}>
									<div className="button_area">
										<Button onclick={close} type={"button"} name={language["edit_menu_cancel_button"]} icon={"ImCancelCircle"}/>
										<Button name={language["edit_menu_save_button"]} icon={"FaSave"} loading={loading}/>
									</div>
								</Col>
							</Row>
						</form>
					</> : type === 101 ? <>
						<form onSubmit={editCard}>
							<Row>
								<Input col={12} title={language["profile_image_input_title"]} icon={"BsFillImageFill"} type={"file"} name={"profile_photo"} accept={"image/*"}/>
							</Row>
							<Row>
								<Col md={12}>
									<div className="button_area">
										<Button onclick={close} type={"button"} name={language["edit_menu_cancel_button"]} icon={"ImCancelCircle"}/>
										<Button name={language["edit_menu_save_button"]} icon={"FaSave"} loading={loading}/>
									</div>
								</Col>
							</Row>
						</form>
					</> : type === 102 ? <>
						<form onSubmit={editCard}>
							<Row>
								<Input col={12} title={language["name_surname_input_title"]} type={"text"} name={"full_name"} icon={"FaSignature"} value={card.full_name} placeholder={language["name_surname_input_placeholder"]}/>
								<Input col={12} title={language["user_address_input_title"]} type={"textarea"} name={"user_address"} icon={"FaMapMarkedAlt"} value={card.user_address} placeholder={language["user_address_input_placeholder"]}/>
								<Input col={12} title={language["user_phone_input_title"]} type={"text"} name={"user_phone"} icon={"BsTelephoneFill"} value={card.user_phone} placeholder={language["user_phone_input_placeholder"]}/>
								<Input col={12} title={language["user_email_input_title"]} type={"text"} name={"user_email"} icon={"BiAt"} value={card.user_email} placeholder={language["user_email_input_placeholder"]}/>
								<Input col={12} title={language["user_website_input_title"]} type={"text"} name={"user_website"} icon={"AiOutlineLink"} value={card.user_website} placeholder={language["user_website_input_placeholder"]}/>
								<Input col={12} title={language["company_position_input_title"]} type={"text"} name={"company_position"} icon={"MdOutlineBusinessCenter"} value={card.company_position} placeholder={language["company_position_input_placeholder"]}/>
							</Row>
							<br/>
							<Row>
								<Input col={12} title={language["company_name_input_title"]} type={"text"} name={"company_name"} icon={"FaSignature"} value={card.company_name} placeholder={language["company_name_input_placeholder"]}/>
								<Input col={12} title={language["company_address_input_title"]} type={"textarea"} name={"company_address"} icon={"FaMapMarkedAlt"} value={card.company_address} placeholder={language["company_address_input_placeholder"]}/>
								<Input col={12} title={language["company_phone_input_title"]} type={"text"} name={"company_phone"} icon={"BsFillTelephoneFill"} value={card.company_phone} placeholder={language["company_phone_input_placeholder"]}/>
								<Input col={12} title={language["company_email_input_title"]} type={"text"} name={"company_email"} icon={"BiAt"} value={card.company_email} placeholder={language["company_email_input_placeholder"]}/>
								<Input col={12} title={language["company_website_input_title"]} type={"text"} name={"company_website"} icon={"AiOutlineLink"} value={card.company_website} placeholder={language["company_website_input_placeholder"]}/>
							</Row>
							<Row>
								<Col md={12}>
									<div className="button_area">
										<Button onclick={close} type={"button"} name={language["edit_menu_cancel_button"]} icon={"ImCancelCircle"}/>
										<Button name={language["edit_menu_save_button"]} icon={"FaSave"} loading={loading}/>
									</div>
								</Col>
							</Row>
						</form>
					</> : type === 103 ? <form onSubmit={editCard}>
						<Row>
							<Input col={12} title={language["page_background_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"page_bg"} value={source.page_bg ?? "#FFFFFF"} forced={true}/>
							<Input col={12} title={language["card_background_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"card_bg"} value={source.card_bg ?? "#FFFFFF"} forced={true}/>
							<Input col={12} title={language["card_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"card_color"} value={source.card_color ?? "#FFFFFF"} forced={true}/>
						</Row>
						<Row>
							<Col md={12}>
								<div className="button_area">
									<Button onclick={close} type={"button"} name={language["edit_menu_cancel_button"]} icon={"ImCancelCircle"}/>
									<Button name={language["edit_menu_save_button"]} icon={"FaSave"} loading={loading}/>
								</div>
							</Col>
						</Row>
					</form> : <></>}
				</div>
			</div>
		</>

	)
}

export default EditMenu