import {createSlice} from "@reduxjs/toolkit";

export const Language = createSlice({
	name: "Language",
	initialState: {
		value: localStorage.getItem("language") || "tr",
		language: []
	},
	reducers: {
		change: (state, action) => {
			localStorage.setItem("language", action.payload.code);
			state.value = action.payload.code
			state.language = action.payload.language
		}
	}
})
export const {change} = Language.actions
export default Language.reducer