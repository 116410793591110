import Icons from "components/Icons";

function Button({icon, name, onclick, type, loading}) {

	return (
		<div className={"button"}>
			{
				loading && <div className="loading"><img src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_IMAGE_PATH}/loading-buffering.gif`} alt=""/></div>
			}
			<button type={type || "submit"} onClick={onclick && onclick}><Icons name={icon}/>{name}</button>
		</div>
	)
}

export default Button