import {useStateIfMounted} from "use-state-if-mounted";
import AddMenu from "main/components/AddMenu";
import {Col, Row} from "react-grid-system";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import Icons from "components/Icons";
import functions from "functions";
import axios from "axios";

function User() {
	let language = useSelector(state => state.Language.language);
	let user_id = useSelector(state => state.Session.id);
	const [menu, setMenu] = useStateIfMounted(false);
	const [cards, setCards] = useStateIfMounted([]);
	const [user, setUser] = useStateIfMounted([]);
	let {id} = useParams();

	async function getCards() {
		let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/list/cards/${id}`)
		await setCards(response.data.result)
	}

	useEffect(() => {
		async function getUser() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get/user/${id}`)
			await setUser(response.data.result)
		}

		void getUser()
		void getCards()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);
	return (<div>
		<div className="panelTitle">{user.email}</div>
		<div className="wrapper">
			{
				menu &&
				<AddMenu componentID={id} type={100} close={() => setMenu(false)} reload={getCards} cardURL={user_id}/>
			}
			<div className={"userCards"}>
				<div className={"addCard"}>
					<button onClick={() => setMenu(true)}><Icons name={"GoPlus"}/>{language["add_card_button"]}</button>
				</div>
				<Row>
					{cards.map((card, index) =>
						<Col md={3} key={index}>
							<div className="userCard">
								<section>
									<div className="info" style={{backgroundColor: card.card_bg}}>
										<h2 style={{color: card.card_color}}>{card.name}</h2>
										<i style={{color: card.card_color}}>/{card.url}</i>
									</div>
									<div className="buttons">
										<button onClick={() => functions.yesno(`${card.card_id}--${user_id}`, language["card_delete_menu_title"], language["card_delete_menu_text"], "delete", "card", getCards, language)}
												data-tip={language["data_tip_delete"]}><Icons name="BiTrashAlt"/></button>
										<a href={`/${card.url}`}><Icons name={"AiOutlineEye"}/></a>
									</div>
								</section>
							</div>
						</Col>
					)}
				</Row>
			</div>
		</div>
	</div>)
}

export default User;