import {useStateIfMounted} from "use-state-if-mounted";
import {isMobile} from "react-device-detect";
import {Row, Col} from "react-grid-system";
import {useSelector} from "react-redux";
import Button from "components/Button";
import Select from "components/Select";
import serialize from "form-serialize";
import Input from "components/Input";
import functions from "functions";
import {useEffect} from "react";
import axios from "axios";

function AddMenu({close, type, cardURL, componentID = 0, reload}) {
	const [componentType, setComponentType] = useStateIfMounted("");
	let language = useSelector(state => state.Language.language);
	const [loading, setLoading] = useStateIfMounted(false);
	const [security, setSecurity] = useStateIfMounted("");
	const [buttons, setButtons] = useStateIfMounted([]);
	const [button, setButton] = useStateIfMounted("");
	const [icon, setIcon] = useStateIfMounted("");

	useEffect(() => {
		async function getButtons() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/list/buttons`)
			await setButtons(response.data.result)
		}

		void getButtons()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [componentID]);

	useEffect(() => {
		async function getButton() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get/button/${icon}`)
			await setButton(response.data.result)
		}

		void getButton()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [icon]);


	async function addComponent(event) {
		event.preventDefault()
		setLoading(true)
		let formData = new FormData(event.target)
		let response = await axios({
			method: "POST", url: `${process.env.REACT_APP_API_URL}/api/add/component/${cardURL}`, data: formData, headers: {
				"Content-Type": "multipart/form-data"
			}
		})
		response = response.data
		if (response.ok) {
			await functions.message("success", language["process_successful"], language[response.ok], false, "", true, "top-end", 1500)
			setLoading(false)
			close()
			reload()
		} else if (response.error) {
			await functions.message("error", language["process_failed"], language[response.error], true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		} else {
			await functions.message("error", language["process_failed"], "", true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		}
	}

	async function addButton(event) {
		event.preventDefault()
		setLoading(true)
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/add/button/${componentID}`, serialize(event.target));
		response = response.data
		if (response.ok) {
			await functions.message("success", language["process_successful"], language[response.ok], false, "", true, "top-end", 1500)
			setLoading(false)
			close()
			reload()
		} else if (response.error) {
			await functions.message("error", language["process_failed"], language[response.error], true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		} else {
			await functions.message("error", language["process_failed"], "", true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		}
	}
	async function addCard(event) {
		event.preventDefault()
		setLoading(true)
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/add/card/${componentID}`, `${serialize(event.target)}&user_id=${cardURL}`);
		response = response.data
		if (response.ok) {
			await functions.message("success", language["process_successful"], language[response.ok], false, "", true, "top-end", 1500)
			setLoading(false)
			close()
			reload()
		} else if (response.error) {
			await functions.message("error", language["process_failed"], language[response.error], true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		} else {
			await functions.message("error", language["process_failed"], "", true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		}
	}
	const pleaseSelect = [{
		label: language["please_select"], value: ""
	}]
	const componentTypes = [{
		label: language["component_type0"], value: "0"
	}, {
		label: language["component_type1"], value: "1"
	}, {
		label: language["component_type2"], value: "2"
	}, {
		label: language["component_type3"], value: "3"
	}, {
		label: language["component_type4"], value: "4"
	}, {
		label: language["component_type5"], value: "5"
	}]
	const wifiHid = [{
		label: language["visible"], value: "false"
	}, {
		label: language["invisible"], value: "true"
	}]
	return (<>
		<div className={"MenuWrapper"}>
			<div className={"MenuCloseBack"} onClick={close}/>
			<div className={"Menu"}>

				{type === 0 ? <>
						<h2>{language["add_menu_title"]}</h2>
						<form onSubmit={addComponent}>
							<Row>
								<Select col={12} title={language["component_type_input_title"]} icon={"CgComponents"} name={"component_type"} placeholder={language["component_type_input_placeholder"]} values={isMobile ? pleaseSelect.concat(componentTypes) : componentTypes} onchange={(value) => setComponentType(value)} forced={true}/>
								{componentType === "0" ? <>
										<Input col={12} title={language["component_type0_title_input_title"]} type={"text"} name={"component_title"} icon={"FaSignature"} placeholder={language["component_type0_title_input_placeholder"]}/>
									</> :
									componentType === "1" ? <>
											<Input col={12} title={language["component_type1_title_input_title"]} type={"text"} name={"component_title"} icon={"FaSignature"} placeholder={language["component_type1_title_input_placeholder"]}/>
											<Input col={12} title={language["component_type1_image_input_title"]} icon={"BsFillImageFill"} type={"file"} name={"image"} accept={"image/*"} forced={true}/>
											<Input col={12} title={language["component_type1_text1_input_title"]} type={"textarea"} name={"component_text1"} icon={"BsInfoLg"} placeholder={language["component_type1_text1_input_placeholder"]}/>
											<Input col={12} title={language["component_type1_text2_input_title"]} type={"text"} name={"component_text2"} icon={"FaSignature"} placeholder={language["component_type1_text2_input_placeholder"]}/>
										</> :
										componentType === "2" ? <>
												<Input col={12} title={language["component_type2_url_input_title"]} icon={"AiOutlineLink"} type={"text"} name={"component_url"} placeholder={language["component_type2_url_input_placeholder"]} forced={true}/>
												<Input col={12} title={language["component_type2_title_input_title"]} type={"text"} name={"component_title"} icon={"FaSignature"} placeholder={language["component_type2_title_input_placeholder"]}/>
												<Input col={12} title={language["component_type2_text1_input_title"]} type={"textarea"} name={"component_text1"} icon={"BsInfoLg"} placeholder={language["component_type2_text1_input_placeholder"]}/>
												<Input col={12} title={language["component_type2_text2_input_title"]} type={"text"} name={"component_text2"} icon={"FaSignature"} placeholder={language["component_type2_text2_input_placeholder"]}/>
											</> :
											componentType === "3" ? <>
													<Input col={12} title={language["component_type3_url_input_title"]} icon={"AiOutlineLink"} type={"text"} name={"component_url"} placeholder={language["component_type3_url_input_placeholder"]} forced={true}/>
												</> :
												componentType === "4" ? <>
														<Input col={12} title={language["component_type4_title_input_title"]} type={"text"} name={"component_title"} icon={"FaSignature"} placeholder={language["component_type4_title_input_placeholder"]}/>
														<Input col={12} title={language["component_type4_network_name_input_title"]} icon={"AiOutlineLink"} type={"text"} name={"wifi_ssid"} placeholder={language["component_type4_network_name_input_placeholder"]} forced={true}/>
														<Col md={12}>
															<div className={"security"}>
																<h2>{language["component_type4_network_security_input_title"]}</h2>
																<section>
																	<button type={"button"} onClick={() => setSecurity("WEP")} className={security === "WEP" ? "active" : ""}>WEP</button>
																	<button type={"button"} onClick={() => setSecurity("WPA")} className={security === "WPA" ? "active" : ""}>WPA/WPA2-PSK</button>
																	<button type={"button"} onClick={() => setSecurity("")} className={security === "" ? "active" : ""}>{language["no_security"]}</button>
																</section>
															</div>
														</Col>
														<Input type={"hidden"} name={"wifi_security"} value={security}/>
														<Input col={12} title={language["component_type4_password_input_title"]} type={"password"} name={"wifi_password"} icon={"FaSignature"} placeholder={language["component_type4_password_input_placeholder"]}/>
														<Select col={12} title={language["component_type4_network_visibility_input_title"]} icon={"CgComponents"} name={"wifi_hidden"} placeholder={language["component_type4_network_visibility_input_placeholder"]} values={isMobile ? pleaseSelect.concat(wifiHid) : wifiHid} forced={true}/>
														<Select col={12} title={language["component_type4_password_visibility_input_title"]} icon={"CgComponents"} name={"component_text1"} placeholder={language["component_type4_password_visibility_input_placeholder"]} values={isMobile ? pleaseSelect.concat(wifiHid) : wifiHid} forced={true}/>
													</> :
													componentType === "5" ? <>
														<Input col={12} title={language["component_type5_title_input_title"]} type={"text"} name={"component_title"} icon={"FaSignature"} placeholder={language["component_type5_title_input_placeholder"]}/>
														<Input col={6} title={language["component_type5_button_background_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"button_bg"} value={"#27ae60"} forced={true}/>
														<Input col={6} title={language["component_type5_button_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"button_color"} value={"#ffffff"} forced={true}/>
													</> : <></>}
								{componentType !== "3" && componentType !== "" && <>
									<Input col={6} title={language["add_menu_background_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"component_bg"} value={"#ffffff"} forced={true}/>
									<Input col={6} title={language["add_menu_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"component_color"} value={"#222222"} forced={true}/>
								</>}
							</Row>
							<Row>
								<Col md={12}>
									<div className="button_area">
										<Button onclick={close} type={"button"} name={language["add_menu_cancel_button"]} icon={"ImCancelCircle"}/>
										<Button name={language["add_menu_add_button"]} icon={"AiOutlinePlus"} loading={loading}/>
									</div>
								</Col>
							</Row>
						</form>
					</> :
					type === 10 ? <>
							<h2>{language["add_button_menu_title"]}</h2>
							<form onSubmit={addButton}>
								<Row>
									<Input col={12} title={language["add_button_link_input_title"]} name={"url"} icon={"AiOutlineLink"} placeholder={language["add_button_link_input_placeholder"]} forced={true}/>
									<Select col={12} title={language["add_button_ikon_input_title"]} name={"icon"} icon={"FaIcons"} forced={true} placeholder={language["add_button_ikon_input_placeholder"]} modern={true} values={buttons} searchable={true} clearable={true} onchange={setIcon}/>
									<Input col={6} title={language["add_menu_background_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"bg_color"} value={button.bg_color ?? "#222222"} forced={true}/>
									<Input col={6} title={language["add_menu_color_input_title"]} type={"color"} icon={"BsFillPaletteFill"} name={"color"} value={button.color ?? "#FFFFFF"} forced={true}/>
								</Row>
								<Row>
									<Col md={12}>
										<div className="button_area">
											<Button onclick={close} type={"button"} name={language["add_menu_cancel_button"]} icon={"ImCancelCircle"}/>
											<Button name={language["add_menu_add_button"]} icon={"AiOutlinePlus"} loading={loading}/>
										</div>
									</Col>
								</Row>
							</form>
						</> :
						type === 100 ? <>
							<h2>{language["add_card_menu_title"]}</h2>
							<form onSubmit={addCard}>
								<Row>
									<Input col={12} title={language["card_name_input_title"]} name={"name"} icon={"AiOutlineLink"} placeholder={language["card_name_input_placeholder"]} forced={true}/>
									<Input col={12} title={language["card_url_input_title"]} name={"url"} icon={"AiOutlineLink"} placeholder={language["card_url_input_placeholder"]} forced={true}/>
								</Row>
								<Row>
									<Col md={12}>
										<div className="button_area">
											<Button onclick={close} type={"button"} name={language["add_menu_cancel_button"]} icon={"ImCancelCircle"}/>
											<Button name={language["add_menu_add_button"]} icon={"AiOutlinePlus"} loading={loading}/>
										</div>
									</Col>
								</Row>
							</form>
						</> : <></>}
			</div>
		</div>
	</>)
}

export default AddMenu