import {useStateIfMounted} from "use-state-if-mounted";
import {useSelector} from "react-redux";
import Move from "main/components/Move";
import Icons from "components/Icons";
import VCard from "vcard-creator";
import {useEffect} from "react";
import axios from "axios";

function AddDirectory({comp, card, cardColor, editMode, editMenu, reload}) {
	const [profilePhoto, setProfilePhoto] = useStateIfMounted("");
	let language = useSelector(state => state.Language.language);
	const [logo, setLogo] = useStateIfMounted("");
	let myVCard = new VCard();
	useEffect(() => {
		async function convertToBase64(file, setter) {
			let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/operation/convert64`, `file=${file !== "" ? file : ""}`)
			setter(response.data.file)
		}

		void convertToBase64(card.company_logo, setLogo)
		void convertToBase64(card.profile_photo, setProfilePhoto)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [card]);

	myVCard
		.addName("", card.full_name ?? "", '', '', '')
		.addCompany(card.company_name ?? "")
		.addJobtitle(card.company_position ?? "")
		.addRole(card.company_position ?? "")
		.addEmail(card.user_email ?? "", "HOME")
		.addEmail(card.company_email ?? "", "WORK")
		.addPhoneNumber(card.user_phone ?? "", "CELL")
		.addPhoneNumber(card.company_phone ?? "", 'WORK')
		.addAddress("", "", card.user_address ?? "", '', '', '', '', "HOME")
		.addAddress("", "", card.company_address ?? "", '', '', '', '', "WORK")
		.addURL(card.user_website ?? "")
		.addURL(card.company_website ?? "")
		.addPhoto(profilePhoto, "PNG")
		.addLogo(logo, "PNG")

	return (
		<>
			<Move editMode={editMode} color={cardColor} direction={1} comp={comp} reload={reload}/>
			<div className={"addDirectory"} style={{backgroundColor: comp.component_bg, color: comp.component_color}}>
				{
					comp.component_title &&
					<h2>{comp.component_title}</h2>
				}
				{
					editMode &&
					<div onClick={editMenu} className={"editArea"}>
						<Icons name={"MdEdit"}/>
					</div>
				}
				<a style={{backgroundColor: comp.component_text1, color: comp.component_text2}} href={`data:text/plain;charset=utf-8,${encodeURIComponent(myVCard.toString())}`} download={`${card.full_name !== "" ? card.full_name : card.url}.vcf`}><Icons name={"AiOutlineUserAdd"}/>{language["component_type5_add_directory"]}</a>
			</div>
			<Move editMode={editMode} color={cardColor} direction={0} comp={comp} reload={reload}/>
		</>
	)
}

export default AddDirectory;