import {useStateIfMounted} from "use-state-if-mounted";
import React, {useMemo, useEffect} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Table from "components/Table";
import Icons from "components/Icons";
import Input from "components/Input";
import functions from "functions";
import axios from "axios";

function Users() {
	const [hiddenColumns, setHiddenColumns] = useStateIfMounted(["expander"]);
	const [windowWidth, setWindowWidth] = useStateIfMounted(window.innerWidth);
	let language = useSelector(state => state.Language.language);
	const [control, setControl] = useStateIfMounted(true);
	let type = useSelector(state => state.Session.type);
	const [users, setUsers] = useStateIfMounted([]);
	let id = useSelector(state => state.Session.id);
	useEffect(() => {
		async function uyeler() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/list/users/${id}`);
			setUsers(response.data.result)
		}

		void uyeler()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		setWindowWidth(window.innerWidth)
		if (windowWidth < 1140) {
			if (control === true) {
				if (hiddenColumns.length === 1) {
					setHiddenColumns(["type", "card_limit", "actions"])
				}
				setControl(false)
			}
		} else {
			if (hiddenColumns.length > 1 && control === false) {
				setHiddenColumns(["expander"])
			}
			setControl(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowWidth])
	window.addEventListener("resize", () => {
		setWindowWidth(window.innerWidth)
	})
	const initialState = {
		sortBy: [],
		hiddenColumns: hiddenColumns,
		pageSize: 20
	}

	async function search() {
		let searchValue = document?.querySelector("input[name=search]")?.value
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/list/users/${id}`, `search=${searchValue}`)
		setUsers(response.data.result)
	}

	const columns = useMemo(
		() => [
			{
				Header: "I/O",
				id: 'expander',
				disableSortBy: true,
				type: "1,2",
				Cell: ({row}) => (
					<i className="noSelect" {...row.getToggleRowExpandedProps()}>{row.isExpanded ? <Icons name="GrClose"/> : <Icons name="GiHamburgerMenu"/>}</i>
				),
				width: 50
			},
			{
				Header: language["table_email"] ?? "",
				disableSortBy: true,
				id: 'email',
				type: "1,2",
				accessor: data => data.email ? <a href={`mailto:${data.email}`}>{data.email}</a> : <Icons name={"AiOutlineMinus"}/>,
			},
			{
				Header: language["table_user_type"] ?? "",
				id: 'type',
				type: "1,2",
				accessor: a => (a.type === "0" ? language["user_type0"] : a.type === "1" ? language["user_type1"] : a.type === "2" ? language["user_type2"] : <Icons name={"AiOutlineMinus"}/>),
			},
			{
				Header: language["table_card_limit"] ?? "",
				id: 'card_limit',
				type: "1,2",
				accessor: a => a.card_limit ? `${JSON.parse(a.cards).length} / ${a.card_limit}` : <Icons name={"AiOutlineMinus"}/>,
			},
			{
				Header: language["table_actions"] ?? "",
				id: 'actions',
				type: "1,2",
				accessor: data => (
					<div className="islemler">
						{
							type >= 1 &&
							<>
								<button className="sil"
										onClick={() => functions.yesno(data.user_id, language["user_delete_menu_title"], language["user_delete_menu_text"], "delete", "user", search, language)}
										data-tip={language["data_tip_delete"]}><Icons name="BiTrashAlt"/></button>
								<Link className="duzenle" to={`/panel/edit/user/${data.user_id}`} data-tip={language["data_tip_edit"]}><Icons name="BiEditAlt"/></Link>
							</>
						}
						<Link rel={"canonical"} className="detay" to={`/panel/user/${data.user_id}`} data-tip={language["data_tip_show_user"]}><Icons name="AiOutlineEye"/></Link>
					</div>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[language]
	);

	return (
		<div>
			<div className="panelTitle">{language["users_page_title"]}</div>
			<div className="wrapper">
				<Input type="text" name="search" icon="AiOutlineSearch" value="" title={language["user_search_input_title"]} col={12} onchange={(value) => search(value)} placeholder={language["among_result"]?.replace("%r", users.length)}/>
				<Table columns={columns} data={users} hiddenColumns={hiddenColumns} initialState={initialState}/>
			</div>
		</div>
	)
}

export default Users;