import {useStateIfMounted} from "use-state-if-mounted";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {change} from "stores/Language";
import Select from "components/Select";
import {Row} from "react-grid-system";
import axios from "axios";

function Main() {
	const [languageList, setLanguageList] = useStateIfMounted([]);
	let language = useSelector(state => state.Language.language);
	const [languages, setLanguages] = useStateIfMounted([]);
	let code = useSelector(state => state.Language.value);
	let dispatch = useDispatch();

	async function getLanguageSource(code) {
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get/languageSource`, `language=${code}`)
		return response.data
	}

	useEffect(() => {
		async function getLanguages() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/list/languages`)
			setLanguages(response.data.result)
			setLanguageList(response.data.list)
		}

		void getLanguageSource()
		void getLanguages()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<div>
			<div className="panelTitle">{language["aside_main_button"]}</div>
			<div className="wrapper">
				<Row>
					<Select col={12} values={languages} title={""} icon={"MdOutlineLanguage"} defaultValue={{value: code, label: languageList[code]}} onchange={async (e) => {
						dispatch(change(await getLanguageSource(e)))
					}} searchable={true}/>
				</Row>
			</div>
		</div>
	)
}

export default Main;


