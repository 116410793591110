import {useStateIfMounted} from "use-state-if-mounted";
import {useHistory} from "react-router-dom";
import {Col, Row} from "react-grid-system";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import Button from "components/Button";
import serialize from "form-serialize";
import Input from "components/Input";
import functions from "functions";
import axios from "axios";

function Icon({id}) {
	let language = useSelector(state => state.Language.language);
	const [loading, setLoading] = useStateIfMounted(false);
	const [icon, setIcon] = useStateIfMounted("");
	const history = useHistory();
	useEffect(() => {
		async function icon() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get/button_list/${id}`)
			await setIcon(response.data.result)
		}

		void icon()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	async function edit(event) {
		event.preventDefault()
		setLoading(true)
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/edit/icon/${id}`, serialize(event.target));
		response = response.data
		if (response.ok) {
			await functions.message("success", language["process_successful"], language[response.ok], false, "", true, "top-end", 1500)
			setTimeout(async function () {
				history.push("/panel/icons")
				setLoading(false)
			}, 1500);
		} else if (response.error) {
			await functions.message("error", language["process_failed"], language[response.error], true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		} else {
			await functions.message("error", language["process_failed"], "", true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		}
	}

	return (
		<div>
			<div className="panelTitle">{language["edit_icon_page_title"]}</div>
			<div className="wrapper">
				<form onSubmit={edit}>
					<Row>
						<Input type={"text"} name={"name"} col={6} icon={"FaSignature"} title={language["icon_name_input_title"]} placeholder={language["icon_name_input_placeholder"]} value={icon.name} forced={true}/>
						<Input type={"text"} name={"icon"} col={6} icon={"FaSignature"} title={language["icon_code_input_title"]} placeholder={language["icon_code_input_placeholder"]} value={icon.icon} forced={true}/>
						<Input type={"color"} name={"bg_color"} col={6} icon={"BsFillPaletteFill"} title={language["background_color_input_title"]} value={icon.bg_color ?? "#444444"} forced={true}/>
						<Input type={"color"} name={"color"} col={6} icon={"BsFillPaletteFill"} title={"icon_color_input_title"} value={icon.color ?? "#FFFFFF"} forced={true}/>
						<Col md={12} className="button_area">
							<Button icon="AiOutlineSave" name={language["save_button"]} loading={loading}/>
						</Col>
					</Row>
				</form>
			</div>
		</div>
	)
}

export default Icon