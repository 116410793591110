import Swal from "sweetalert2";
import axios from "axios";

const serialize = require('form-serialize');

const functions = {
	message: async function message(icon, title, text, buton, butonText, toast, position, timer) {
		Swal.fire({
			icon: icon,
			title: title,
			html: text,
			showConfirmButton: buton,
			confirmButtonText: butonText,
			toast: toast,
			position: position,
			timer: timer,
			timerProgressBar: true,
			didOpen: (toast) => {
				toast.addEventListener('mouseenter', Swal.stopTimer)
				toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		}).then(() => {

		});
	},
	form: async function form(form, url, buton, butonText, toast, position, timer, lang) {
		let response = await axios.post(url, serialize(form));
		response = response.data
		if (response.ok) {
			await functions.message("success", lang["process_successful"], lang[response.ok], buton, butonText, toast, position, timer)
		} else if (response.error) {
			await functions.message("error", lang["process_failed"], lang[response.error], buton, butonText, toast, position, timer)
		} else {
			await functions.message("error", lang["process_failed"], "", true, "Kapat", toast, position, timer)
		}
	},
	yesno: async function yesno(id, title, text, action, page, reload, lang, close) {
		Swal.fire({
			title: title,
			text: text,
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085D6',
			cancelButtonColor: '#D33',
			confirmButtonText: lang["delete_menu_confirmbutton"] ?? "Yes",
			cancelButtonText: lang["delete_menu_cancel_button"] ?? "No"
		}).then(async (result) => {
			if (result.value) {
				await functions.form("", `${process.env.REACT_APP_API_URL}/api/${action}/${page}/${id}`, false, "", true, "top-end", 1000, lang)
				reload()
				close && close()
			}
		});
	},
	settings: async function settings(name) {
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get/settings`, `name=${name}`);
		response = response.data
		return response
	}
}

export default functions;