import {Col, Row} from "react-grid-system";
import {useSelector} from "react-redux";
import serialize from "form-serialize";
import Button from "components/Button";
import Input from "components/Input";
import functions from "functions";
import axios from "axios";

function CardAddMenu({close, reload}) {
	let id = useSelector(state => state.Session.id)

	async function addCard(event) {
		event.preventDefault()
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/add/card/${id}`, serialize(event.target));
		response = response.data
		if (response.ok) {
			await functions.message("success", "İşlem Başarılı", response.ok, false, "", true, "top-end", 1500)
			close()
			reload()
		} else if (response.error) {
			await functions.message("error", "İşlem Başarısız", response.error, true, "Kapat", false, "center", 0)
		} else {
			await functions.message("error", "İşlem Başarısız", "Post işlemi geçersiz.", true, "Kapat", false, "center", 0)
		}
	}

	return (
		<div className={"MenuWrapper"}>
			<div className={"MenuCloseBack"} onClick={close}/>
			<div className={"Menu"}>
				<h2>Yeni Kart Ekle</h2>
				<form onSubmit={addCard}>
					<Row>
						<Input col={12} title={"Kart Adı"} type={"text"} name={"name"} icon={"FaSignature"} placeholder={"Lütfen kart adını giriniz."} forced={true}/>
						<Input col={12} title={"URL"} type={"text"} name={"url"} icon={"AiOutlineLink"} placeholder={"Lütfen kartınızın url'sini giriniz."} forced={true}/>
					</Row>
					<Row>
						<Col md={12}>
							<div className="button_area">
								<Button onclick={close} type={"button"} name={"Vazgeç"} icon={"ImCancelCircle"}/>
								<Button name={"Ekle"} icon={"AiOutlinePlus"}/>
							</div>
						</Col>
					</Row>
				</form>
			</div>
		</div>
	)
}

export default CardAddMenu;