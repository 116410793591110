import {useStateIfMounted} from "use-state-if-mounted";
import CardAddMenu from "main/components/CardAddMenu";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import axios from "axios";

function Kartlarim() {
	const [cards, setCards] = useStateIfMounted(null);
	const [menu, setMenu] = useStateIfMounted(false);
	let id = useSelector(state => state.Session.id)

	async function getCards() {
		let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/list/cards/${id}`)
		await setCards(response.data.result)
	}

	useEffect(() => {
		void getCards()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);
	return (
		<div className={"myCards"}>
			{menu && <CardAddMenu close={() => setMenu(false)} reload={() => getCards()}/>}
			{
				cards ?
					cards.map((card, i) =>
						<a key={i} href={"/" + card.url} className={"myCard"} style={{backgroundColor: card.card_bg}}>
							<section>
								<h4 style={{color: card.card_color}}>{card.name}</h4>
								<span style={{color: card.card_color}}>/{card.url}</span>
							</section>
						</a>
					) :
					<>

					</>
			}
		</div>
	)
}

export default Kartlarim;