import Move from "main/components/Move";
import Icons from "components/Icons";

function VideoBox({comp, cardColor, editMode, editMenu, reload}) {
    let splitURL = comp.component_url.split("=");
    return (
        <>
            <Move editMode={editMode} color={cardColor} direction={1} comp={comp} reload={reload}/>
            <div className={"videoBox"} style={{backgroundColor: comp.component_bg, color: comp.component_color}}>
                {
                    editMode &&
                    <div onClick={editMenu} className={"editArea"}>
                        <Icons name={"MdEdit"}/>
                    </div>
                }
                <div className="body" style={{backgroundColor: comp.component_bg, color: comp.component_color}}>
                    <div className="video">
                        <iframe
                            src={`https://www.youtube.com/embed/${splitURL[1]}`}
                            title={"Youtube Video"}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                    </div>
                    <h2>{comp.component_title}</h2>
                    <p>{comp.component_text1}</p>
                </div>
                {
                    comp.component_text2 &&
                    <div className="footer" style={{backgroundColor: comp.component_bg, color: comp.component_color}}>{comp.component_text2}</div>
                }
            </div>
            <Move editMode={editMode} color={cardColor} direction={0} comp={comp} reload={reload}/>
        </>
    )
}

export default VideoBox