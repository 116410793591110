import {useTable, useSortBy, usePagination, useFlexLayout, useExpanded} from 'react-table';
import ReactTooltip from "react-tooltip";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import Icons from "components/Icons";

function ReactTable(columns, data, initialState, hiddenColumns) {
	let language = useSelector(state => state.Language.language);
	let type = useSelector(state => state.Session.type);

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		gotoPage,
		previousPage,
		setPageSize,
		nextPage,
		page,
		rows,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		allColumns,
		state: {pageIndex, pageSize},
	} = useTable({
			columns,
			data,
			initialState
		},
		useSortBy,
		useExpanded,
		usePagination,
		useFlexLayout
	)
	let rowColor = true;
	useEffect(() => {
		allColumns.map(column =>
			hiddenColumns.includes(column.id) ? column.toggleHidden(true) : column.toggleHidden(false)
		)
	}, [allColumns, hiddenColumns]);
	return (
		<div className="container">
			<ReactTooltip effect="solid"/>
			<div className="table" {...getTableProps()}>
				<div>
					{headerGroups.map(headerGroup => (
						<div className="tr" {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, i) => {
								let tableRutbe = (column.type + ",").split(",")
								return (
									tableRutbe.indexOf(type) !== -1 &&
									<div key={i} className="th" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <span>
                                            {column.render('Header')}
											{!column.disableSortBy ? column.isSorted ? column.isSortedDesc ? <Icons name="BiSortZA"/> : <Icons name="BiSortAZ"/> : <Icons name="BiSortAlt2"/> : ""}
                                        </span>
									</div>
								)
							})}
						</div>
					))}
				</div>
				<div {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row)
						return (
							<React.Fragment key={i}>
								<div className={`tr${rowColor ? " rowColor" : ""}`} {...row.getRowProps()}>
									{row.cells.map((cell, index) => {
										let tableRutbe = (cell.column.type + ",").split(",")
										return (
											tableRutbe.indexOf(type) !== -1 &&
											<div key={index} className="td" {...cell.getCellProps()}>{cell.render('Cell')}</div>
										)
									})}
									{rowColor = !rowColor}
								</div>
								{row.isExpanded ? (
									<div className="tr trmobil">
										<div className="td">
											{row.allCells.map((cell, ind) => {
												let tableRutbe = (cell.column.type + ",").split(",")
												return (
													tableRutbe.indexOf(type) !== -1 &&
													<div key={ind} className="td" {...cell.getCellProps()}>
														<section>{cell.column.Header}</section>
														<section>{cell.render('Cell')}</section>
													</div>
												)
											})}
											{rowColor = !rowColor}
										</div>
									</div>
								) : null}
							</React.Fragment>
						)
					})}
				</div>
			</div>

			<div className="pagination">
				<div className="pageControl">
					<section>
						<select
							value={pageSize}
							onChange={e => {
								setPageSize(Number(e.target.value))
							}}>
							{[20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 1000].map(pageSize => (

								rows.length > pageSize ?
									<option key={pageSize} value={pageSize}>
										{language["among_show"]?.replace("%s", pageSize)}
									</option> : null

							))}
							<option value={rows.length}>{language["show_all"]}</option>
						</select>
					</section>
					<section>
						<span>{language["goto_page"]}</span>
						<input type="number" defaultValue={pageIndex + 1} onChange={e => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(page)
						}}
							   style={{width: '100px'}} min="1" max={pageOptions.length}
						/>
					</section>
				</div>
				<div className="pageInfo">
					<strong>{pageIndex + 1} / {pageOptions.length}</strong>
				</div>
				<nav>
					<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}><Icons name="MdFirstPage"/></button>
					<button onClick={() => previousPage()} disabled={!canPreviousPage}><Icons name="MdNavigateBefore"/></button>
					<button onClick={() => nextPage()} disabled={!canNextPage}><Icons name="MdNavigateNext"/></button>
					<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}><Icons name="MdLastPage"/></button>
				</nav>
			</div>
		</div>
	);
}

function Table({columns, data, initialState, hiddenColumns}) {

	return (
		<>
			{ReactTable(columns, data, initialState, hiddenColumns)}
		</>
	)
}

export default Table;