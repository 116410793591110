import {useHistory} from "react-router-dom";
import {Col, Row} from "react-grid-system";
import {useSelector} from "react-redux";
import Button from "components/Button";
import serialize from "form-serialize";
import Input from "components/Input";
import functions from "functions";
import axios from "axios";
import {useStateIfMounted} from "use-state-if-mounted";

function Language() {
	let language = useSelector(state => state.Language.language);
	const [loading, setLoading] = useStateIfMounted(false);
	const history = useHistory();

	async function add(event) {
		event.preventDefault()
		setLoading(true)
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/add/language`, serialize(event.target));
		response = response.data
		if (response.ok) {
			await functions.message("success", language["process_successful"], language[response.ok], false, "", true, "top-end", 1500)
			setTimeout(function () {
				history.push("/panel/languages")
				setLoading(false)
			}, 1500);
		} else if (response.error) {
			await functions.message("error", language["process_failed"], language[response.error], true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		} else {
			await functions.message("error", language["process_failed"], "", true, language["process_failed_close_button"], false, "center", 0)
			setLoading(false)
		}
	}
	return (
		<div>
			<div className="panelTitle">{language["add_language_page_title"]}</div>
			<div className="wrapper">
				<form onSubmit={add}>
					<Row>
						<Input type={"text"} name={"name"} col={6} icon={"FaSignature"} title={language["language_name_input_title"]} placeholder={language["language_name_input_placeholder"]} forced={true}/>
						<Input col={6} type={"text"} name={"code"} icon={"FaLanguage"} title={language["language_code_input_title"]} placeholder={language["language_code_input_placeholder"]} forced={true}/>
						<Col md={12} className="button_area">
							<Button icon="GoPlus" name={language["add_button"]} loading={loading}/>
						</Col>
					</Row>
				</form>
			</div>
		</div>
	)
}

export default Language