import AddDirectory from "main/components/AddDirectory";
import {useStateIfMounted} from "use-state-if-mounted";
import {useDispatch, useSelector} from "react-redux";
import SpotifyBox from "main/components/SpotifyBox";
import ImageBox from "main/components/ImageBox";
import VideoBox from "main/components/VideoBox";
import EditMenu from "main/components/EditMenu";
import WifiCode from "main/components/WifiCode";
import {firstclose} from "stores/LoadingScreen";
import InfoBox from "main/components/InfoBox";
import AddMenu from "main/components/AddMenu";
import QrCode from "main/components/QrCode";
import {useParams} from "react-router-dom";
import {RWebShare} from "react-web-share";
import React, {useEffect} from "react";
import {change} from "stores/Language";
import Select from "components/Select";
import Icons from "components/Icons";
import axios from "axios";

function Card() {
	const [languageList, setLanguageList] = useStateIfMounted([]);
	const [componentID, setComponentID] = useStateIfMounted("");
	let language = useSelector(state => state.Language.language);
	const [editSource, setEditSource] = useStateIfMounted([]);
	const [editMenu, setEditMenu] = useStateIfMounted(false);
	const [editMode, setEditMode] = useStateIfMounted(false);
	const [languages, setLanguages] = useStateIfMounted([]);
	const [addMenu, setAddMenu] = useStateIfMounted(false);
	const [qrMenu, setQrMenu] = useStateIfMounted(false);
	const [menuType, setMenuType] = useStateIfMounted(0);
	let code = useSelector(state => state.Language.value);
	let type = useSelector(state => state.Session.type);
	const [button, setButton] = useStateIfMounted("");
	const [card, setCard] = useStateIfMounted(null);
	let id = useSelector(state => state.Session.id);
	let dispatch = useDispatch();
	const {url} = useParams();

	async function getLanguageSource(code) {
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get/languageSource`, `language=${code}`)
		return response.data
	}

	useEffect(() => {
		async function getLanguages() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/list/languages`)
			setLanguages(response.data.result)
			setLanguageList(response.data.list)
		}

		void getLanguageSource()
		void getLanguages()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	async function reload() {
		let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get/card/${url}`)
		await setCard(response.data.result)
	}

	async function getCard() {
		let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get/card/${url}`)
		dispatch(firstclose())
		await setCard(response.data.result)
	}

	useEffect(() => {
		void getCard()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url]);

	return (card ? <div className="cardWrapper" style={{backgroundColor: card.page_bg}}>
		{addMenu && <AddMenu close={() => setAddMenu(false)} type={menuType} cardURL={url} componentID={componentID}
							 reload={() => reload()}/>}
		{editMenu && <EditMenu close={() => setEditMenu(false)} type={menuType} cardURL={url} componentID={componentID}
							   buttonSRC={button} source={editSource} reload={() => reload()}/>}
		{qrMenu && <QrCode value={`https://${process.env.REACT_APP_SUBDOMAIN}.${process.env.REACT_APP_DOMAIN}/${url}`} close={() => setQrMenu(false)}/>}
		{card ? <div className="card" style={{backgroundColor: card.card_bg, color: card.card_color}}>
			{
				editMode &&
				<div className="languageSelect">
					<Select col={12} values={languages} title={""} icon={"MdOutlineLanguage"} defaultValue={{value: code, label: languageList[code]}} onchange={async (e) => {
						dispatch(change(await getLanguageSource(e)))
					}}/>
				</div>
			}
			{editMode && <div className={"cardEditBar"}>
				<button onClick={() => {
					setEditMenu(true)
					setMenuType(103)
					setEditSource(card)
				}}>{language["card_edit_button"]}
				</button>
			</div>}
			<div className="cardHeader">
				<div className="actionBar">
					<section>
						{(card.reseller_id === id || card.user_id === id || type === "2") && <button style={{color: card.banner_color}} onClick={() => setEditMode(!editMode)}><Icons
							name={editMode ? "MdEditOff" : "MdEdit"}/></button>}
					</section>
					<section>
						<RWebShare
							data={{
								text: "Merhaba, benimle ilgili merak ettiğin her şeyi paylaşıyorum.", url: `https://${process.env.REACT_APP_SUBDOMAIN}.${process.env.REACT_APP_DOMAIN}/${url}`, title: "Kartvizit Linki"
							}}
						>
							<button style={{color: card.banner_color}}><Icons name={"AiOutlineShareAlt"}/></button>
						</RWebShare>
						<button style={{color: card.banner_color}} onClick={() => setQrMenu(!qrMenu)}><Icons name={"MdOutlineQrCode2"}/></button>
					</section>
				</div>
				<section
					style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}${process.env.REACT_APP_IMAGE_PATH}/${card.banner_image})`}}>
					{editMode && <div onClick={() => {
						setEditMenu(true)
						setMenuType(100)
						setEditSource(card)
					}} className={"editArea"}>
						<Icons name={"MdEdit"}/>
					</div>}
					<div className="businessLogoWrapper">
						<div className="businessLogo">
							{card.company_logo && <img
								src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_IMAGE_PATH}/${card.company_logo}`}
								alt=""/>}
						</div>
					</div>
				</section>
				<div className="profile">
					<div className="photo">
						{card.profile_photo && <img
							src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_IMAGE_PATH}/${card.profile_photo}`}
							alt=""/>}
						{editMode && <div onClick={() => {
							setEditMenu(true)
							setMenuType(101)
							setEditSource(card)
						}} className={"editArea"}>
							<Icons name={"MdEdit"}/>
						</div>}
					</div>
					<section>
						{editMode && <div onClick={() => {
							setEditMenu(true)
							setMenuType(102)
							setEditSource(card)
						}} className={"editArea"}>
							<Icons name={"MdEdit"}/>
						</div>}
						<h2>{card.full_name}</h2>
						<i>{card.company_name}</i>
						<b>{card.company_position}</b>
						<div className={"details"}>
							<ul>
								{card.user_address &&
									<li>
										<Icons name={"FaMapMarkedAlt"}/>
										<span><a rel="noreferrer" target={"_blank"} href={`https://www.google.com/maps/search/${card.user_address}`}>{card.user_address}</a></span>
									</li>}
								{card.user_phone &&
									<li>
										<Icons name={"BsTelephoneFill"}/>
										<span><a href={`tel:${card.user_phone}`}>{card.user_phone}</a></span>
									</li>}
								{card.user_email &&
									<li>
										<Icons name={"BiAt"}/>
										<span><a href={`mailto:${card.user_email}`}>{card.user_email}</a></span>
									</li>}
								{card.user_website &&
									<li>
										<Icons name={"AiOutlineLink"}/>
										<span><a trel="noreferrer" arget={"_blank"} href={card.user_website}>{card.user_website}</a></span>
									</li>}
								{card.company_address &&
									<li>
										<Icons name={"FaMapMarkedAlt"}/>
										<span><a rel="noreferrer" target={"_blank"} href={`https://www.google.com/maps/search/${card.company_address}`}>{card.company_address}</a></span>
									</li>}
								{card.company_phone &&
									<li>
										<Icons name={"BsFillTelephoneFill"}/>
										<span><a href={`tel:${card.company_phone}`}>{card.company_phone}</a></span>
									</li>}
								{card.company_email &&
									<li>
										<Icons name={"BiAt"}/>
										<span><a href={`mailto:${card.company_email}`}>{card.company_email}</a></span>
									</li>}
								{card.company_website &&
									<li>
										<Icons name={"AiOutlineLink"}/>
										<span><a rel="noreferrer" target={"_blank"} href={card.company_website}>{card.company_website}</a></span>
									</li>}
							</ul>
						</div>
					</section>
				</div>
			</div>
			<div className="cardBody">
				{card.components && JSON.parse(card.components).map((comp, i) => comp.component_type === 0 ? <InfoBox key={i} comp={comp} source={JSON.parse(comp.component_buttons)} cardColor={card.card_color} editMode={editMode} addMenu={() => {
					setAddMenu(true)
					setMenuType(10);
					setComponentID(comp.component_id)
				}} editMenu={() => {
					setEditMenu(true)
					setEditSource(comp)
					setMenuType(0);
					setComponentID(comp.component_id)
				}} editButton={(src) => {
					setEditMenu(true)
					setEditSource(comp)
					setMenuType(10);
					setComponentID(comp.component_id)
					setButton(src)
				}} reload={getCard}/> : comp.component_type === 1 ? <ImageBox key={i} comp={comp} cardColor={card.card_color} editMode={editMode} editMenu={() => {
					setEditMenu(true)
					setEditSource(comp)
					setMenuType(1);
					setComponentID(comp.component_id)
				}} reload={getCard}/> : comp.component_type === 2 ? <VideoBox key={i} comp={comp} cardColor={card.card_color} editMode={editMode} editMenu={() => {
					setEditMenu(true)
					setEditSource(comp)
					setMenuType(2);
					setComponentID(comp.component_id)
				}} reload={getCard}/> : comp.component_type === 3 ? <SpotifyBox key={i} comp={comp} cardColor={card.card_color} editMode={editMode} editMenu={() => {
					setEditMenu(true)
					setEditSource(comp)
					setMenuType(3);
					setComponentID(comp.component_id)
				}} reload={getCard}/> : comp.component_type === 4 ? <WifiCode key={i} comp={comp} cardColor={card.card_color} editMode={editMode} editMenu={() => {
					setEditMenu(true)
					setEditSource(comp)
					setMenuType(4);
					setComponentID(comp.component_id)
				}} reload={getCard}/> : comp.component_type === 5 ? <AddDirectory key={i} comp={comp} card={card} cardColor={card.card_color} editMode={editMode} editMenu={() => {
					setEditMenu(true)
					setEditSource(comp)
					setMenuType(5);
					setComponentID(comp.component_id)
				}} reload={getCard}/> : <></>)}

				{editMode && <div onClick={() => {
					setAddMenu(true)
					setMenuType(0)
				}} className={"addComponent"}>
					<button style={{borderColor: card.card_color, color: card.card_color,}}><Icons
						name={"AiOutlinePlusCircle"}/></button>
				</div>}
			</div>
		</div> : <></>}
	</div> : <></>)
}

export default Card;