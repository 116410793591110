import {useStateIfMounted} from "use-state-if-mounted";
import {isMobile} from 'react-device-detect'
import React, {useEffect} from "react";
import {Col} from "react-grid-system";
import Icons from "components/Icons";
import Select2 from 'react-select';


function Select({col, title, name, values, defaultValue, icon, onchange, forced = false, placeholder = "", modern = true, searchable = false, clearable = false}) {
    const [selectedOption, setSelectedOption] = useStateIfMounted(defaultValue);
    useEffect(() => {
        setSelectedOption(defaultValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);
    return (
        <Col md={col}>
            <label>
                {
                    title &&
                    <div className="input_title">{title ? title : ""}{forced && <span><Icons name={"FaStarOfLife"}/></span>}</div>
                }
                <div className="input_area">
                    {
                        modern && !isMobile ?
                            <Select2
                                name={name}
                                value={selectedOption}
                                onChange={(e) => {
                                    setSelectedOption(e)
                                    onchange && onchange(e.value)
                                }}
                                isSearchable={searchable}
                                isClearable={clearable}
                                options={values}
                                placeholder={placeholder}/>
                            :
                            <select
                                className={"input"}
                                name={name}
                                value={selectedOption}
                                placeholder={placeholder}
                                onChange={(e) => {
                                    setSelectedOption(e.target.value);
                                    onchange && onchange(e.target.value)
                                }}
                            >
                                {values.map((value, index) => {
                                    return <option key={index} value={value.value}>{value.label}</option>
                                })}
                            </select>
                    }
                    <i>
                        {
                            icon === "" ? <Icons name="FaRegDotCircle"/> : <Icons name={icon}/>
                        }
                    </i>
                </div>
            </label>
        </Col>
    )
}
export default Select