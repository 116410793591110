import {useStateIfMounted} from "use-state-if-mounted";
import {useDispatch, useSelector} from "react-redux";
import {close, toggle} from "stores/MenuToggle";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import Icons from "components/Icons";
import Menu from 'json/aside.json';
import Slide from "js/Slide";

export default function Aside() {
	const [windowWidth, setWindowWidth] = useStateIfMounted(window.innerWidth);
	let menuValue = useSelector(state => state.MenuToggle.value);
	let language = useSelector(state => state.Language.language);
	let type = useSelector(state => state.Session.type);
	const [menu, setmenu] = useStateIfMounted([]);
	const dispatch = useDispatch();
	useEffect(() => {
		setmenu(Menu);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	window.addEventListener("resize", () => {
		setWindowWidth(window.innerWidth)
	})
	useSelector(state => state.MenuToggle.value)
	let drop = <Icons name="AiOutlineRight"/>;
	return (//${(windowWidth - 64) >= 240 ? "240" : (windowWidth - 64)}
		<aside style={{width: menuValue === true ? `${windowWidth <= 500 ? windowWidth : 240}px` : "0rem"}} className="noSelect">
			<div className="logo">
				{/*https://joynfc.com/wp-content/uploads/2019/10/kopya222-kopya.png*/}
				{/*${process.env.REACT_APP_API_URL}/img/logo_turuncu2.png*/}
				<Link rel={"canonical"} to="/"><img src={`https://joynfc.com/wp-content/uploads/2019/10/kopya222-kopya.png`} alt=""/></Link>
				{windowWidth <= 500 && <button onClick={() => dispatch(toggle())}><Icons name={"GoThreeBars"}/></button>}
			</div>
			<nav>
				<ul>
					{menu.length && menu.map((button, index) => {
						let buttonType = (button.type + ",").split(",")
						return (buttonType.indexOf(type) !== -1 && <li
							key={index}
							id={"menu" + button.id}>
							{button.link === "" ? (<section
								onClick={() => {
									let clickedButton = document.querySelector("#menu" + button.id);
									setmenu(menu.map(el => el.id === button.id ? {...button, dropdownActive: !el.dropdownActive} : el))
									let dropdownMenu = "#menu" + button.id + " > ul";
									button.dropdownActive ? Slide(dropdownMenu, "up", 300) : Slide(dropdownMenu, "down", 300)
									button.dropdownActive ? clickedButton.classList.remove("active") : clickedButton.classList.add("active");
								}}>
								<em><Icons name={button.icon}/></em>
								<span>{language[button.title]}</span>
								{button.dropdown && drop}
							</section>) : (<Link
								rel={"canonical"}
								onClick={() => {
									if (windowWidth < 1000) {
										dispatch(close())
									}
								}} to={button.link === "" ? "#" : button.link}>
								<em><Icons name={button.icon}/></em>
								<span>{language[button.title]}</span>
								{button.dropdown && drop}
							</Link>)}

							{button.dropdown && <ul>
								{button.dropdownMenu.map((button, index) => {
									let buttonType = (button.type + ",").split(",")
									return (buttonType.indexOf(type) !== -1 && <li
										rel={"canonical"}
										key={index}>
										<Link rel={"canonical"} onClick={() => {
											if (windowWidth < 1000) {
												dispatch(close())
											}
										}}
											  to={button.link}><Icons name={button.icon}/>{language[button.title]}</Link>
									</li>)
								})}
							</ul>}
						</li>)
					})}
				</ul>
			</nav>
		</aside>)
}
