import Move from "main/components/Move";
import Icons from "components/Icons";

function SpotifyBox({comp, editMode, editMenu, cardColor, reload}) {
    let splitURL;
    let type;
    if (comp.component_url.search("playlist") !== -1) {
        splitURL = comp.component_url.split("playlist")[1].split("?")[0]
        type = "playlist"
    } else if (comp.component_url.search("album") !== -1) {
        splitURL = comp.component_url.split("album")[1].split("?")[0]
        type = "album"
    } else if (comp.component_url.search("track") !== -1) {
        splitURL = comp.component_url.split("track")[1].split("?")[0]
        type = "track"
    } else if (comp.component_url.search("artist") !== -1) {
        splitURL = comp.component_url.split("artist")[1].split("?")[0]
        type = "artist"
    }
    return (
        <>
            <Move editMode={editMode} color={cardColor} direction={1} comp={comp} reload={reload}/>
            <div className={"spotifyBox"}>
                {
                    editMode &&
                    <div onClick={editMenu} className={"editArea"}>
                        <Icons name={"MdEdit"}/>
                    </div>
                }
                <div className="playlist">
                    <iframe
                        src={`https://open.spotify.com/embed/${type}${splitURL}`}
                        title={"Spotify"}
                        frameBorder="0"
                        allow="encrypted-media"/>
                </div>
            </div>
            <Move editMode={editMode} color={cardColor} direction={0} comp={comp} reload={reload}/>
        </>
    )
}

export default SpotifyBox