import * as Fa from "react-icons/fa";
import * as Io from "react-icons/io";
import * as Md from "react-icons/md";
import * as Ti from "react-icons/ti";
import * as Go from "react-icons/go";
import * as Fi from "react-icons/fi";
import * as Gi from "react-icons/gi";
import * as Wi from "react-icons/wi";
import * as Di from "react-icons/di";
import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import * as Ri from "react-icons/ri";
import * as Fc from "react-icons/fc";
import * as Gr from "react-icons/gr";
import * as Hi from "react-icons/hi";
import * as Si from "react-icons/si";
import * as Im from "react-icons/im";
import * as Bi from "react-icons/bi";
import * as Cg from "react-icons/cg";
import * as Vsc from "react-icons/vsc";

const DynamicFaIcon = ({name = "AiOutlineQuestionCircle"}) => {
    const IconComponent = Fa[name] ?? Io[name] ?? Md[name] ?? Ti[name] ?? Go[name] ?? Fi[name] ??
        Gi[name] ?? Wi[name] ?? Di[name] ?? Ai[name] ?? Bs[name] ?? Ri[name] ?? Fc[name] ?? Gr[name] ??
        Hi[name] ?? Si[name] ?? Im[name] ?? Bi[name] ?? Cg[name] ?? Vsc[name] ?? Ai["AiOutlineQuestionCircle"];
    return <IconComponent/>;
};

function Icons({name}) {
    return (
        <>{DynamicFaIcon({name})}</>
    )
}
export default Icons;