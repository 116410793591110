import {useStateIfMounted} from "use-state-if-mounted";
import {Col} from "react-grid-system";
import Icons from "components/Icons";
import {useEffect} from "react";
import React from "react";

function Input({col, title, type, name, value, icon, onchange, placeholder, accept = "", fast = false, dataid = "", forced = false}) {
    const [changeValue, setchangeValue] = useStateIfMounted(value);

    useEffect(() => {
        async function setValue(){
            await setchangeValue(value)
        }
        void setValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    return (
        <Col md={col}>
            <label>
                {
                    title &&
                    <div className="input_title">{title ? title : ""}{forced && <span><Icons name={"FaStarOfLife"}/></span>}</div>
                }
                <div className="input_area">
                    {
                        fast ?
                            <input multiple accept={accept ? accept : ""} className="input" type={type ? type : "text"} name={name}
                                   onChange={e => {
                                       type === "file" ?
                                           onchange && onchange(e)
                                           :
                                           onchange && onchange(e.target.value)
                                   }
                                   } placeholder={placeholder}/>
                            :
                            type === "textarea" ?
                                <textarea className="input" name={name} rows="5" defaultValue={changeValue ? changeValue : ""} onChange={e => {
                                    setchangeValue(e.target.value)
                                    onchange && onchange(e.target.value)
                                }} placeholder={placeholder}/>
                                :
                                <input {...accept ? accept = {accept} : ""} data-id={dataid ? dataid : 0} className="input" type={type ? type : "text"} name={name} value={changeValue ? changeValue : ""}
                                       onChange={e => {
                                           setchangeValue(e.target.value)
                                           type === "file" ?
                                               onchange && onchange(e)
                                               :
                                               onchange && onchange(e.target.value)
                                       }
                                       } placeholder={placeholder}/>
                    }
                    <i>
                        {
                            type !== "hidden" ? icon === "" ? <Icons name="FaRegDotCircle"/> : <Icons name={icon}/> : ''
                        }
                    </i>
                </div>
            </label>
        </Col>
    )
}
export default Input