import {useStateIfMounted} from "use-state-if-mounted";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Row, Col} from "react-grid-system";
import React, {useEffect} from "react";
import {change} from "stores/Language";
import Select from "components/Select";
import Button from "components/Button";
import serialize from "form-serialize";
import Input from "components/Input";
import {login} from "stores/Session";
import functions from "functions";
import axios from "axios";

function Giris() {
	const [languageList, setLanguageList] = useStateIfMounted([]);
	let language = useSelector(state => state.Language.language);
	const [languages, setLanguages] = useStateIfMounted([]);
	let code = useSelector(state => state.Language.value);
	const dispatch = useDispatch();
	const history = useHistory();

	async function getLanguageSource(code) {
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get/languageSource`, `language=${code}`)
		return response.data
	}

	useEffect(() => {
		async function getLanguages() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/list/languages`)
			setLanguages(response.data.result)
			setLanguageList(response.data.list)
		}

		void getLanguageSource()
		void getLanguages()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	async function formLogin(event) {
		event.preventDefault();
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/operation/login`, serialize(event.target));
		response = response.data
		if (response.ok) {
			await functions.message("success", language["process_successful"], language[response.ok], false, "", true, "top-end", 1500)
			setTimeout(function () {
				dispatch(login(response))
				history.push("/")
			}, 1500);
		} else if (response.error) {
			await functions.message("error", language["process_failed"], language[response.error], true, language["process_failed_close_button"], false, "center", 1000)
		}
	}

	return (
		<div className={"form"}>
			<form onSubmit={formLogin}>
				<Row>
					<Col md={7}></Col>
					<Select col={5} values={languages} icon={"MdOutlineLanguage"} defaultValue={{value: code, label: languageList[code]}} onchange={async (e) => {
						dispatch(change(await getLanguageSource(e)))
					}}/>
				</Row>
				<h2>{language["login_title"]}</h2>
				<Row>
					<Input col={12} name={"email"} type={"email"} title={language["login_email_input_title"]} placeholder={language["login_email_input_placeholder"]} forced={true} icon={"BiAt"}/>
					<Input col={12} name={"password"} type={"password"} title={language["login_password_input_title"]} placeholder={language["login_password_input_placeholder"]} forced={true} icon={"MdPassword"}/>
				</Row>
				<Row>
					<Col md={12}>
						<div className="button_area">
							<Button name={language["login_button"]} icon={"AiOutlineLogin"}/>
						</div>
					</Col>
				</Row>
			</form>
		</div>
	)
}

export default Giris;