import Move from "main/components/Move";
import Icons from "components/Icons";

function ImageBox({comp, cardColor, editMode, editMenu, reload}) {
    return (
        <>
            <Move editMode={editMode} color={cardColor} direction={1} comp={comp} reload={reload}/>
            <div className={"imageBox"} style={{backgroundColor: comp.component_bg, color: comp.component_color}}>
                {
                    editMode &&
                    <div onClick={editMenu} className={"editArea"}>
                        <Icons name={"MdEdit"}/>
                    </div>
                }
                <div className="header" style={{backgroundColor: comp.component_bg, color: comp.component_color}}>
                    <h2>{comp.component_title}</h2>
                </div>
                <div className="body" style={{backgroundColor: comp.component_bg, color: comp.component_color}}>
                    <img src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_IMAGE_PATH}/${comp.component_url}`} alt=""/>
                    <p>{comp.component_text1}</p>
                </div>
                {
                    comp.component_text2 &&
                    <div className="footer" style={{backgroundColor: comp.component_bg, color: comp.component_color}}>{comp.component_text2}</div>
                }
            </div>
            <Move editMode={editMode} color={cardColor} direction={0} comp={comp} reload={reload}/>
        </>
    )
}

export default ImageBox