import {createSlice} from "@reduxjs/toolkit";

export const Session = createSlice({
    name: "Session",
    initialState: {
        id: 0,
        token: "",
        auth: false,
        loaded: false,
        type: 0
    },
    reducers: {
        login: (state, action) => {
            state.id = action.payload.id
            state.token = action.payload.token
            state.auth = true
            state.loaded = true
            state.type = action.payload.type
            localStorage.setItem("auth", "true")
            localStorage.setItem("id", action.payload.id)
            localStorage.setItem("token", action.payload.token)
            localStorage.setItem("type", action.payload.type)
        },
        load: state => {
            state.loaded = true
        },
        logout: state => {
            state.id = 0
            state.token = ""
            state.auth = false
            state.loaded = true
            state.type = 0
            localStorage.setItem('auth', "false")
            localStorage.setItem('id', "")
            localStorage.setItem('token', "")
            localStorage.setItem('type', "")
        }
    }
})
export const {login, load, logout} = Session.actions
export default Session.reducer

