import {useStateIfMounted} from "use-state-if-mounted";
import React, {useMemo, useEffect} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Table from "components/Table";
import Icons from "components/Icons";
import Input from "components/Input";
import functions from "functions";
import axios from "axios";

function Languages() {
	const [hiddenColumns, setHiddenColumns] = useStateIfMounted(["expander"]);
	const [windowWidth, setWindowWidth] = useStateIfMounted(window.innerWidth);
	let language = useSelector(state => state.Language.language);
	const [languages, setLanguages] = useStateIfMounted([]);
	const [control, setControl] = useStateIfMounted(true);
	let type = useSelector(state => state.Session.type);
	useEffect(() => {
		async function getLanguages() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/list/languages`);
			setLanguages(response.data.result)
		}

		void getLanguages()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		setWindowWidth(window.innerWidth)
		if (windowWidth < 1140) {
			if (control === true) {
				if (hiddenColumns.length === 1) {
					setHiddenColumns([""])
				}
				setControl(false)
			}
		} else {
			if (hiddenColumns.length > 1 && control === false) {
				setHiddenColumns(["expander"])
			}
			setControl(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowWidth])

	window.addEventListener("resize", () => {
		setWindowWidth(window.innerWidth)
		console.log(windowWidth)
	})
	const initialState = {
		sortBy: [],
		hiddenColumns: hiddenColumns,
		pageSize: 20
	}

	async function search(value) {
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/list/languages`, `search=${value}`)
		setLanguages(response.data.result)
	}

	const columns = useMemo(
		() => [
			{
				Header: "I/O",
				id: 'expander',
				disableSortBy: true,
				type: "1,2",
				Cell: ({row}) => (
					<i className="noSelect" {...row.getToggleRowExpandedProps()}>{row.isExpanded ? <Icons name="GrClose"/> : <Icons name="GiHamburgerMenu"/>}</i>
				),
				width: 50
			},
			{
				Header: language["table_language_name"] ?? "",
				disableSortBy: true,
				id: 'name',
				type: "1,2",
				accessor: data => data.label,
			},
			{
				Header: language["table_actions"] ?? "",
				id: 'islemler',
				type: "1,2",
				accessor: data => (
					<div className="islemler">
						{
							type >= 1 &&
							<>
								<button className="sil"
										onClick={() => functions.yesno(data.value, language["language_delete_menu_title"], language["language_delete_menu_text"], "delete", "language", search, language)}
										data-tip={language["data_tip_delete"]}><Icons name="BiTrashAlt"/></button>
								<Link className="duzenle" to={`/panel/edit/language/${data.value}`} data-tip={language["data_tip_edit"]}><Icons name="BiEditAlt"/></Link>
							</>
						}
						<Link rel={"canonical"} className="detay" to={`/panel/language/${data.value}`} data-tip={language["data_tip_show_language"]}><Icons name="AiOutlineEye"/></Link>
					</div>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[language]
	);

	return (
		<div>
			<div className="panelTitle">{language["languages_page_title"]}</div>
			<div className="wrapper">
				<Input type="text" name="search" icon="AiOutlineSearch" value="" title={language["language_search_input_title"]} col={12} onchange={(value) => search(value)} placeholder={language["among_result"]?.replace("%r", languages.length)}/>
				<Table columns={columns} data={languages} hiddenColumns={hiddenColumns} initialState={initialState}/>
			</div>
		</div>
	)
}

export default Languages;