import InfoButton from "main/components/InfoButton";
import Move from "main/components/Move";
import Icons from "components/Icons";

function InfoBox({comp, source, cardColor, editMode, addMenu, editMenu, editButton, reload}) {
    return (
        <>
            <Move editMode={editMode} color={cardColor} direction={1} comp={comp} reload={reload}/>
            <div className="infoBox" style={{backgroundColor: comp.component_bg, color: comp.component_color}}>
                <h2>{comp.component_title} {editMode && <button onClick={editMenu}><Icons name={"MdEdit"}/></button>}</h2>
                <section>
                    {
                        source &&
                        source.map((src, i) => <InfoButton key={i} src={src} id={src.button_id} bgColor={src.bg_color} color={src.color} icon={src.icon} url={src.url} editMode={editMode} editMenu={editButton}/>)
                    }

                </section>
                {
                    editMode &&
                    <div className={"addButton"}>
                        <button onClick={addMenu} style={{backgroundColor: comp.component_bg, color: comp.component_color, borderColor: comp.component_color}}><Icons name={"BsPlusLg"}/></button>
                    </div>
                }
            </div>
            <Move editMode={editMode} color={cardColor} direction={0} comp={comp} reload={reload}/>
        </>
    )
}

export default InfoBox;