import {useStateIfMounted} from "use-state-if-mounted";
import {useDispatch, useSelector} from "react-redux";
import {hoverDropdown} from "dropdown-menu-basic";
import {useHistory} from "react-router-dom";
import {toggle} from "stores/MenuToggle";
import React, {useEffect} from "react";
import {logout} from "stores/Session";
import Icons from 'components/Icons';
import functions from "functions";
import Swal from "sweetalert2";
import axios from "axios";

function Header() {
	let language = useSelector(state => state.Language.language);
	let id = useSelector(state => state.Session.id);
	const [user, setUser] = useStateIfMounted("");
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		async function getUser() {
			let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get/user/${id}`)
			setUser(response.data.result)
		}

		void getUser()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);
	hoverDropdown()

	function cikis() {
		Swal.fire({
			title: language["logout_title"], text: language["logout_text"], icon: 'question', showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: language["logout_confirm"], cancelButtonText: language["logout_cancel"]
		}).then((result) => {
			if (result.value) {
				void axios.get(`${process.env.REACT_APP_API_URL}/api/operation/logout`)
				void functions.message("success", language["process_successful"], language["logout_successful"], false, "", true, "top-end", 1500, false, "")
				setTimeout(function () {
					dispatch(logout())
					history.push("/")
				}, 1500);
			}
		})
	}

	return (<header className={"panelHeader"}>
		<ul>
			<li>
				<button onClick={() => dispatch(toggle())}><Icons name={"GoThreeBars"}/></button>
			</li>
		</ul>
		<ul>
			<li className="dropdown-hover">
				<button className="dropdown-btn"><Icons name={"AiOutlineUser"}/><span>{`${user.email}`}</span></button>
				<ul className="dropdown-menu">
					<li>
						<button onClick={() => cikis()}>{language["logout_button"]}</button>
					</li>
				</ul>
			</li>
		</ul>
	</header>)
}

export default Header;