import {configureStore} from '@reduxjs/toolkit'
import MenuToggle from "stores/MenuToggle";
import Session from "stores/Session";
import LoadingScreen from "stores/LoadingScreen";
import Language from "stores/Language";
export default configureStore({
    reducer: {
        MenuToggle: MenuToggle,
        Session: Session,
        LoadingScreen: LoadingScreen,
        Language: Language
    },
})