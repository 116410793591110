import {useStateIfMounted} from "use-state-if-mounted";
import {useHistory, useParams} from "react-router-dom";
import {Col, Row} from "react-grid-system";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import Button from "components/Button";
import serialize from "form-serialize";
import Input from "components/Input";
import functions from "functions";
import axios from "axios";

function Language() {
	let selectedLanguage = useSelector(state => state.Language.language);
	let [languageName, setLanguageName] = useStateIfMounted([]);
	let [language, setLanguage] = useStateIfMounted([]);
	let history = useHistory();
	let {lang} = useParams();

	async function getLanguageSource() {
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get/languageSource`, `language=${lang}`)
		setLanguage(response.data.language)
	}

	useEffect(() => {
		async function getLanguageName() {
			let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get/languageName`, `language=${lang}`)
			setLanguageName(response.data.result)
		}

		void getLanguageSource()

		void getLanguageName()
		void getLanguageSource()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function edit(event) {
		event.preventDefault()
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/edit/languageSource/${lang}`, serialize(event.target));
		response = response.data
		if (response.ok) {
			await functions.message("success", selectedLanguage["process_successful"], selectedLanguage[response.ok], false, "", true, "top-end", 1500, false, "")
			setTimeout(async function () {
				history.push(`/panel/language/${lang}`)
				await getLanguageSource()
			}, 1500);
		} else if (response.error) {
			await functions.message("error", selectedLanguage["process_failed"], selectedLanguage[response.error], true, selectedLanguage["process_failed_close_button"], false, "center", 0, false, "")
		} else {
			await functions.message("error", selectedLanguage["process_failed"], "", true, selectedLanguage["process_failed_close_button"], false, "center", 0, false, "")
		}
	}

	return (
		<div>
			<div className="panelTitle">{languageName}</div>
			<div className="wrapper">
				<form onSubmit={edit}>
					<fieldset>
						<legend>Genel</legend>
						<Row>
							<Input col={3} title={"Başarısız İşlem Kapat Butonu"} icon={"FaLanguage"} name={"process_failed_close_button"} value={language["process_failed_close_button"]}/>
							<Input col={3} title={"İşlem Başarılı"} icon={"FaLanguage"} name={"process_successful"} value={language["process_successful"]}/>
							<Input col={3} title={"İşlem Başarısız"} icon={"FaLanguage"} name={"process_failed"} value={language["process_failed"]}/>
							<Input col={3} title={"Telefonda Lütfen Seçiniz"} icon={"FaLanguage"} name={"please_select"} value={language["please_select"]}/>
							<Input col={3} title={"Beklenmedik Bir Hata"} icon={"FaLanguage"} name={"unexpected_error"} value={language["unexpected_error"]}/>
							<Input col={3} title={"Sil Menü Onay Butonu"} icon={"FaLanguage"} name={"delete_menu_confirm_button"} value={language["delete_menu_confirm_button"]}/>
							<Input col={3} title={"Sil Menü Red Butonu"} icon={"FaLanguage"} name={"delete_menu_cancel_button"} value={language["delete_menu_cancel_button"]}/>
							<Input col={3} title={"Kaydet Butonu"} icon={"FaLanguage"} name={"save_button"} value={language["save_button"]}/>
							<Input col={3} title={"Ekle Butonu"} icon={"FaLanguage"} name={"add_button"} value={language["add_button"]}/>
							<Input col={3} title={"Yeni Kartvizit Ekle Butonu"} icon={"FaLanguage"} name={"add_card_button"} value={language["add_card_button"]}/>
							<Input col={3} title={"%s Tane Göster"} icon={"FaLanguage"} name={"among_show"} value={language["among_show"]}/>
							<Input col={3} title={"Tümünü Göster"} icon={"FaLanguage"} name={"show_all"} value={language["show_all"]}/>
							<Input col={3} title={"Sayfaya Git"} icon={"FaLanguage"} name={"goto_page"} value={language["goto_page"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Üye Tipleri</legend>
						<Row>
							<Input col={4} title={"Üye Tipi 0"} icon={"FaLanguage"} name={"user_type0"} value={language["user_type0"]}/>
							<Input col={4} title={"Üye Tipi 1"} icon={"FaLanguage"} name={"user_type1"} value={language["user_type1"]}/>
							<Input col={4} title={"Üye Tipi 2"} icon={"FaLanguage"} name={"user_type2"} value={language["user_type2"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Tablo Başlıkları</legend>
						<Row>
							<Input col={3} title={"Dil Adı"} icon={"FaLanguage"} name={"table_language_name"} value={language["table_language_name"]}/>
							<Input col={3} title={"E-posta Adresi"} icon={"FaLanguage"} name={"table_email"} value={language["table_email"]}/>
							<Input col={3} title={"Üye Tipi"} icon={"FaLanguage"} name={"table_user_type"} value={language["table_user_type"]}/>
							<Input col={3} title={"Kart Limiti"} icon={"FaLanguage"} name={"table_card_limit"} value={language["table_card_limit"]}/>
							<Input col={3} title={"İkon"} icon={"FaLanguage"} name={"table_icon"} value={language["table_icon"]}/>
							<Input col={3} title={"İkon Adı"} icon={"FaLanguage"} name={"table_icon_name"} value={language["table_icon_name"]}/>
							<Input col={3} title={"İkon Kodu"} icon={"FaLanguage"} name={"table_icon_code"} value={language["table_icon_code"]}/>
							<Input col={3} title={"İşlemler"} icon={"FaLanguage"} name={"table_actions"} value={language["table_actions"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>İşlem Butonlarının Üstüne Gelince Çıkan Yazılar</legend>
						<Row>
							<Input col={3} title={"Sil"} icon={"FaLanguage"} name={"data_tip_delete"} value={language["data_tip_delete"]}/>
							<Input col={3} title={"Düzenle"} icon={"FaLanguage"} name={"data_tip_edit"} value={language["data_tip_edit"]}/>
							<Input col={3} title={"Dili Görüntüle"} icon={"FaLanguage"} name={"data_tip_show_language"} value={language["data_tip_show_language"]}/>
							<Input col={3} title={"Üyeyi Görüntüle"} icon={"FaLanguage"} name={"data_tip_show_user"} value={language["data_tip_show_user"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Input Başlıkları ve Placeholder</legend>
						<Row>
							<Input col={3} title={"İkon Arama"} icon={"FaLanguage"} name={"icon_search_input_title"} value={language["icon_search_input_title"]}/>
							<Input col={3} title={"Üye Arama"} icon={"FaLanguage"} name={"user_search_input_title"} value={language["user_search_input_title"]}/>
							<Input col={3} title={"Dil Arama"} icon={"FaLanguage"} name={"language_search_input_title"} value={language["language_search_input_title"]}/>
							<Input col={3} title={"%r Sonuç Arasından Ara"} icon={"FaLanguage"} name={"among_result"} value={language["among_result"]}/>
							<Input col={3} title={"Dil Adı Input Başlığı"} icon={"FaLanguage"} name={"language_name_input_title"} value={language["language_name_input_title"]}/>
							<Input col={3} title={"Dil Adı Input Placeholder"} icon={"FaLanguage"} name={"language_name_input_placeholder"} value={language["language_name_input_placeholder"]}/>
							<Input col={3} title={"Dil Kodu Input Başlığı"} icon={"FaLanguage"} name={"language_code_input_title"} value={language["language_code_input_title"]}/>
							<Input col={3} title={"Dil Kodu Input Placeholder"} icon={"FaLanguage"} name={"language_code_input_placeholder"} value={language["language_code_input_placeholder"]}/>
							<Input col={3} title={"E-posta Adresi Input Başlığı"} icon={"FaLanguage"} name={"email_input_title"} value={language["email_input_title"]}/>
							<Input col={3} title={"E-posta Adresi Input Placeholder"} icon={"FaLanguage"} name={"email_input_placeholder"} value={language["email_input_placeholder"]}/>
							<Input col={3} title={"Şifre Input Başlığı"} icon={"FaLanguage"} name={"password_input_title"} value={language["password_input_title"]}/>
							<Input col={3} title={"Şifre Input Placeholder"} icon={"FaLanguage"} name={"password_input_placeholder"} value={language["password_input_placeholder"]}/>
							<Input col={3} title={"Kart Limiti Input Başlığı"} icon={"FaLanguage"} name={"card_limit_input_title"} value={language["card_limit_input_title"]}/>
							<Input col={3} title={"Kart Limiti Input Placeholder"} icon={"FaLanguage"} name={"card_limit_input_placeholder"} value={language["card_limit_input_placeholder"]}/>
							<Input col={3} title={"Üye Tipi Input Başlığı"} icon={"FaLanguage"} name={"user_type_input_title"} value={language["user_type_input_title"]}/>
							<Input col={3} title={"Üye Tipi Input Placeholder"} icon={"FaLanguage"} name={"user_type_input_placeholder"} value={language["user_type_input_placeholder"]}/>
							<Input col={3} title={"İkon Adı Input Başlığı"} icon={"FaLanguage"} name={"icon_name_input_title"} value={language["icon_name_input_title"]}/>
							<Input col={3} title={"İkon Adı Input Placeholder"} icon={"FaLanguage"} name={"icon_name_input_placeholder"} value={language["icon_name_input_placeholder"]}/>
							<Input col={3} title={"İkon Kodu Input Başlığı"} icon={"FaLanguage"} name={"icon_code_input_title"} value={language["icon_code_input_title"]}/>
							<Input col={3} title={"İkon Kodu Input Placeholder"} icon={"FaLanguage"} name={"icon_code_input_placeholder"} value={language["icon_code_input_placeholder"]}/>
							<Input col={3} title={"Arka Plan Rengi Input Başlığı"} icon={"FaLanguage"} name={"background_color_input_title"} value={language["background_color_input_title"]}/>
							<Input col={3} title={"İkon Rengi Input Başlığı"} icon={"FaLanguage"} name={"icon_color_input_title"} value={language["icon_color_input_title"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Sayfa Başlıkları</legend>
						<Row>
							<Input col={3} title={"Ana Sayfa Sayfası"} icon={"FaLanguage"} name={"main_page_title"} value={language["main_page_title"]}/>
							<Input col={3} title={"İkonlar Sayfası"} icon={"FaLanguage"} name={"icons_page_title"} value={language["icons_page_title"]}/>
							<Input col={3} title={"Üyeler Sayfası"} icon={"FaLanguage"} name={"users_page_title"} value={language["users_page_title"]}/>
							<Input col={3} title={"Diller Sayfası"} icon={"FaLanguage"} name={"languages_page_title"} value={language["languages_page_title"]}/>
							<Input col={4} title={"İkon Ekle Sayfası"} icon={"FaLanguage"} name={"add_icon_page_title"} value={language["add_icon_page_title"]}/>
							<Input col={4} title={"Üye Ekle Sayfası"} icon={"FaLanguage"} name={"add_user_page_title"} value={language["add_user_page_title"]}/>
							<Input col={4} title={"Dil Ekle Sayfası"} icon={"FaLanguage"} name={"add_language_page_title"} value={language["add_language_page_title"]}/>
							<Input col={4} title={"İkon Düzenle Sayfası"} icon={"FaLanguage"} name={"edit_icon_page_title"} value={language["edit_icon_page_title"]}/>
							<Input col={4} title={"Üye Düzenle Sayfası"} icon={"FaLanguage"} name={"edit_user_page_title"} value={language["edit_user_page_title"]}/>
							<Input col={4} title={"Dil Düzenle Sayfası"} icon={"FaLanguage"} name={"edit_language_page_title"} value={language["edit_language_page_title"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Çıkış Yap</legend>
						<Row>
							<Input col={3} title={"Çıkış Yap Butonu"} icon={"FaLanguage"} name={"logout_button"} value={language["logout_button"]}/>
							<Input col={3} title={"Çıkış Yap Menü Başlığı"} icon={"FaLanguage"} name={"logout_title"} value={language["logout_title"]}/>
							<Input col={3} title={"Çıkış Yap Menü Yazısı"} icon={"FaLanguage"} name={"logout_text"} value={language["logout_text"]}/>
							<Input col={3} title={"Çıkış Yap Menü Onay"} icon={"FaLanguage"} name={"logout_confirm"} value={language["logout_confirm"]}/>
							<Input col={3} title={"Çıkış Yap Menü Red"} icon={"FaLanguage"} name={"logout_cancel"} value={language["logout_cancel"]}/>
						</Row>
					</fieldset>
					<div className={"panelTitle"}>Panel Yan Menü</div>
					<Row>
						<Input col={3} title={"Ana Sayfa Butonu"} icon={"FaLanguage"} name={"aside_main_button"} value={language["aside_main_button"]}/>
						<Input col={3} title={"İkon İşlemleri Menü Butonu"} icon={"FaLanguage"} name={"icon_actions_menu_button"} value={language["icon_actions_menu_button"]}/>
						<Input col={3} title={"İkon Ekle Butonu"} icon={"FaLanguage"} name={"icon_add_button"} value={language["icon_add_button"]}/>
						<Input col={3} title={"Tüm İkonlar Butonu"} icon={"FaLanguage"} name={"all_icon_button"} value={language["all_icon_button"]}/>
						<Input col={3} title={"Üye İşlemleri Menü Butonu"} icon={"FaLanguage"} name={"user_actions_menu_button"} value={language["user_actions_menu_button"]}/>
						<Input col={3} title={"Üye Ekle Butonu"} icon={"FaLanguage"} name={"user_add_button"} value={language["user_add_button"]}/>
						<Input col={3} title={"Tüm Üyeler Butonu"} icon={"FaLanguage"} name={"all_user_button"} value={language["all_user_button"]}/>
						<Input col={3} title={"Dil İşlemleri Menü Butonu"} icon={"FaLanguage"} name={"language_actions_menu_button"} value={language["language_actions_menu_button"]}/>
						<Input col={3} title={"Dil Ekle Butonu"} icon={"FaLanguage"} name={"language_add_button"} value={language["language_add_button"]}/>
						<Input col={3} title={"Tüm Diller Butonu"} icon={"FaLanguage"} name={"all_languages_button"} value={language["all_languages_button"]}/>
						<Input col={3} title={"Siteye Dön Butonu"} icon={"FaLanguage"} name={"return_website"} value={language["return_website"]}/>
					</Row>
					<div className={"panelTitle"}>Giriş Formu</div>
					<Row>
						<Input col={3} title={"Form Başlığı"} icon={"FaLanguage"} name={"login_title"} value={language["login_title"]}/>
						<Input col={3} title={"Email Input Başlığı"} icon={"FaLanguage"} name={"login_email_input_title"} value={language["login_email_input_title"]}/>
						<Input col={3} title={"Email Input Placeholder"} icon={"FaLanguage"} name={"login_email_input_placeholder"} value={language["login_email_input_placeholder"]}/>
						<Input col={3} title={"Şifre Input Başlığı"} icon={"FaLanguage"} name={"login_password_input_title"} value={language["login_password_input_title"]}/>
						<Input col={3} title={"Şifre Input Placeholder"} icon={"FaLanguage"} name={"login_password_input_placeholder"} value={language["login_password_input_placeholder"]}/>
						<Input col={3} title={"Giriş Butonu"} icon={"FaLanguage"} name={"login_button"} value={language["login_button"]}/>
					</Row>
					<div className={"panelTitle"}>Api Cevapları</div>
					<fieldset>
						<legend>Giriş/Çıkış Mesajları</legend>
						<Row>
							<Input col={6} title={"Boş Alan Bırakmayınız Hatası"} icon={"FaLanguage"} name={"login_no_empty_area"} value={language["login_no_empty_area"]}/>
							<Input col={6} title={"E-mail veya Password Hatası"} icon={"FaLanguage"} name={"email_or_password_failed"} value={language["email_or_password_failed"]}/>
							<Input col={6} title={"Giriş Başarılı"} icon={"FaLanguage"} name={"login_successful"} value={language["login_successful"]}/>
							<Input col={6} title={"Çıkış Yapıldı"} icon={"FaLanguage"} name={"logout_successful"} value={language["logout_successful"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Taşıma Mesajları</legend>
						<Row>
							<Input col={4} title={"Taşıma Başarılı"} icon={"FaLanguage"} name={"move_successful"} value={language["move_successful"]}/>
							<Input col={4} title={"Daha Yukarı Taşınamaz"} icon={"FaLanguage"} name={"move_up_failed"} value={language["move_up_failed"]}/>
							<Input col={4} title={"Daha Aşağı Taşınamaz"} icon={"FaLanguage"} name={"move_down_failed"} value={language["move_down_failed"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Üye Düzenleme/Ekleme/Silme</legend>
						<Row>
							<Input col={4} title={"Boş Alan Bırakmayınız Hatası"} icon={"FaLanguage"} name={"user_no_empty_area"} value={language["user_no_empty_area"]}/>
							<Input col={4} title={"E-posta Adresi Kullanılıyor Hatası"} icon={"FaLanguage"} name={"user_email_already_used"} value={language["user_email_already_used"]}/>
							<Input col={4} title={"Kart Limiti 0 Olamaz Hatası"} icon={"FaLanguage"} name={"user_card_limit_no0"} value={language["user_card_limit_no0"]}/>
							<Input col={4} title={"Üye Eklendi Mesajı"} icon={"FaLanguage"} name={"user_add_successful"} value={language["user_add_successful"]}/>
							<Input col={4} title={"Üye Düzenlendi Mesajı"} icon={"FaLanguage"} name={"user_edit_successful"} value={language["user_edit_successful"]}/>
							<Input col={4} title={"Üye Silindi Mesajı"} icon={"FaLanguage"} name={"user_delete_successful"} value={language["user_delete_successful"]}/>
							<Input col={4} title={"Üye Sil Menü Başlığı"} icon={"FaLanguage"} name={"user_delete_menu_title"} value={language["user_delete_menu_title"]}/>
							<Input col={4} title={"Üye Sil Menü Yazısı"} icon={"FaLanguage"} name={"user_delete_menu_text"} value={language["user_delete_menu_text"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Kart Düzenleme/Ekleme/Silme Mesajları</legend>
						<Row>
							<Input col={4} title={"Boş Alan Bırakmayınız Hatası"} icon={"FaLanguage"} name={"card_no_empty_area"} value={language["card_no_empty_area"]}/>
							<Input col={4} title={"URL Kullanılıyor Hatası"} icon={"FaLanguage"} name={"card_url_already_used"} value={language["card_url_already_used"]}/>
							<Input col={4} title={"Kart Limitiniz Dolu"} icon={"FaLanguage"} name={"card_limit_is_full"} value={language["card_limit_is_full"]}/>
							<Input col={4} title={"Resim Yükleme Başarısız"} icon={"FaLanguage"} name={"image_upload_failed"} value={language["image_upload_failed"]}/>
							<Input col={4} title={"Kart Eklendi Mesajı"} icon={"FaLanguage"} name={"card_add_successful"} value={language["card_add_successful"]}/>
							<Input col={4} title={"Kart Düzenlendi Mesajı"} icon={"FaLanguage"} name={"card_edit_successful"} value={language["card_edit_successful"]}/>
							<Input col={4} title={"Kart Silindi Mesajı"} icon={"FaLanguage"} name={"card_delete_successful"} value={language["card_delete_successful"]}/>
							<Input col={4} title={"Üye Size Ait Değil"} icon={"FaLanguage"} name={"card_not_belong_to_user"} value={language["card_not_belong_to_user"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>İçerik Düzenleme/Ekleme/Silme Mesajları</legend>
						<Row>
							<Input col={6} title={"Boş Alan Bırakmayınız Hatası"} icon={"FaLanguage"} name={"component_no_empty_area"} value={language["component_no_empty_area"]}/>
							<Input col={6} title={"İçerik Eklendi Mesajı"} icon={"FaLanguage"} name={"component_add_successful"} value={language["component_add_successful"]}/>
							<Input col={6} title={"İçerik Düzenlendi Mesajı"} icon={"FaLanguage"} name={"component_edit_successful"} value={language["component_edit_successful"]}/>
							<Input col={6} title={"İçerik Silindi Mesajı"} icon={"FaLanguage"} name={"component_delete_successful"} value={language["component_delete_successful"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Buton Düzenleme/Ekleme/Silme Mesajları</legend>
						<Row>
							<Input col={6} title={"Boş Alan Bırakmayınız Hatası"} icon={"FaLanguage"} name={"button_no_empty_area"} value={language["button_no_empty_area"]}/>
							<Input col={6} title={"Buton Eklendi Mesajı"} icon={"FaLanguage"} name={"button_add_successful"} value={language["button_add_successful"]}/>
							<Input col={6} title={"Buton Düzenlendi Mesajı"} icon={"FaLanguage"} name={"button_edit_successful"} value={language["button_edit_successful"]}/>
							<Input col={6} title={"Buton Silindi Mesajı"} icon={"FaLanguage"} name={"button_delete_successful"} value={language["button_delete_successful"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>İkon Düzenleme/Ekleme/Silme Mesajları</legend>
						<Row>
							<Input col={6} title={"Boş Alan Bırakmayınız Hatası"} icon={"FaLanguage"} name={"icon_no_empty_area"} value={language["icon_no_empty_area"]}/>
							<Input col={6} title={"İkon Zaten Mevcut"} icon={"FaLanguage"} name={"icon_already_available"} value={language["icon_already_available"]}/>
							<Input col={4} title={"İkon Eklendi Mesajı"} icon={"FaLanguage"} name={"icon_add_successful"} value={language["icon_add_successful"]}/>
							<Input col={4} title={"İkon Düzenlendi Mesajı"} icon={"FaLanguage"} name={"icon_edit_successful"} value={language["icon_edit_successful"]}/>
							<Input col={4} title={"İkon Silindi Mesajı"} icon={"FaLanguage"} name={"icon_delete_successful"} value={language["icon_delete_successful"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Dil Düzenleme/Ekleme/Silme Mesajları</legend>
						<Row>
							<Input col={4} title={"Boş Alan Bırakmayınız Hatası"} icon={"FaLanguage"} name={"language_no_empty_area"} value={language["language_no_empty_area"]}/>
							<Input col={4} title={"Dil Zaten Var Hatası"} icon={"FaLanguage"} name={"language_already_exists"} value={language["language_already_exists"]}/>
							<Input col={4} title={"Dil Eklendi Mesajı"} icon={"FaLanguage"} name={"language_add_successful"} value={language["language_add_successful"]}/>
							<Input col={4} title={"Dil Düzenlendi Mesajı"} icon={"FaLanguage"} name={"language_edit_successful"} value={language["language_edit_successful"]}/>
							<Input col={4} title={"Dil Kaynağı Düzenlendi Mesajı"} icon={"FaLanguage"} name={"language_source_edit_successful"} value={language["language_source_edit_successful"]}/>
							<Input col={4} title={"Dil Silindi Mesajı"} icon={"FaLanguage"} name={"language_delete_successful"} value={language["language_delete_successful"]}/>
							<Input col={4} title={"Dil Sil Menü Başlığı"} icon={"FaLanguage"} name={"language_delete_menu_title"} value={language["language_delete_menu_title"]}/>
							<Input col={4} title={"Dil Sil Menü Yazısı"} icon={"FaLanguage"} name={"language_delete_menu_text"} value={language["language_delete_menu_text"]}/>
						</Row>
					</fieldset>
					<div className={"panelTitle"}>Kartvizit</div>
					<fieldset>
						<legend>Kart Ekle</legend>
						<Row>
							<Input col={4} title={"Menü Başlığı"} icon={"FaLanguage"} name={"add_card_menu_title"} value={language["add_card_menu_title"]}/>
							<Input col={4} title={"Kart Adı Input Başlığı"} icon={"FaLanguage"} name={"card_name_input_title"} value={language["card_name_input_title"]}/>
							<Input col={4} title={"Kart Adı Input Placeholder"} icon={"FaLanguage"} name={"card_name_input_placeholder"} value={language["card_name_input_placeholder"]}/>
							<Input col={4} title={"Kart URL Input Başlığı"} icon={"FaLanguage"} name={"card_url_input_title"} value={language["card_url_input_title"]}/>
							<Input col={4} title={"Kart URL Input Placeholder"} icon={"FaLanguage"} name={"card_url_input_placeholder"} value={language["card_url_input_placeholder"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Kartı Düzenle</legend>
						<Row>
							<Input col={3} title={"Kartı Düzenle Butonu"} icon={"FaLanguage"} name={"card_edit_button"} value={language["card_edit_button"]}/>
							<Input col={3} title={"Sayfa Arka Plan Rengi Input Başlığı"} icon={"FaLanguage"} name={"page_background_color_input_title"} value={language["page_background_color_input_title"]}/>
							<Input col={3} title={"Kart Arka Plan Rengi Input Başlığı"} icon={"FaLanguage"} name={"card_background_color_input_title"} value={language["card_background_color_input_title"]}/>
							<Input col={3} title={"Yazı Rengi Input Başlığı"} icon={"FaLanguage"} name={"card_color_input_title"} value={language["card_color_input_title"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Kart Sil</legend>
						<Row>
							<Input col={6} title={"Sil Menü Başlığı"} icon={"FaLanguage"} name={"card_delete_menu_title"} value={language["card_delete_menu_title"]}/>
							<Input col={6} title={"Sil Menü Yazısı"} icon={"FaLanguage"} name={"card_delete_menu_text"} value={language["card_delete_menu_text"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Şirket Logosu Bölümü</legend>
						<Row>
							<Input col={4} title={"Arka Plan Görseli Input Başlığı"} icon={"FaLanguage"} name={"company_background_image_input_title"} value={language["company_background_image_input_title"]}/>
							<Input col={4} title={"Şirket Logosu Input Başlığı"} icon={"FaLanguage"} name={"company_image_input_title"} value={language["company_image_input_title"]}/>
							<Input col={4} title={"Yazı Rengi Input Başlığı"} icon={"FaLanguage"} name={"company_color_input_title"} value={language["company_color_input_title"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Profil Bilgileri</legend>
						<Row>
							<Input col={3} title={"Ad Soyad Input Başlığı"} icon={"FaLanguage"} name={"name_surname_input_title"} value={language["name_surname_input_title"]}/>
							<Input col={3} title={"Ad Soyad Input Placeholder"} icon={"FaLanguage"} name={"name_surname_input_placeholder"} value={language["name_surname_input_placeholder"]}/>
							<Input col={3} title={"Adres Input Başlığı"} icon={"FaLanguage"} name={"user_address_input_title"} value={language["user_address_input_title"]}/>
							<Input col={3} title={"Adres Input Placeholder"} icon={"FaLanguage"} name={"user_address_input_placeholder"} value={language["user_address_input_placeholder"]}/>
							<Input col={3} title={"Telefon Input Başlığı"} icon={"FaLanguage"} name={"user_phone_input_title"} value={language["user_phone_input_title"]}/>
							<Input col={3} title={"Telefon Input Placeholder"} icon={"FaLanguage"} name={"user_phone_input_placeholder"} value={language["user_phone_input_placeholder"]}/>
							<Input col={3} title={"E-posta Input Başlığı"} icon={"FaLanguage"} name={"user_email_input_title"} value={language["user_email_input_title"]}/>
							<Input col={3} title={"E-posta Input Placeholder"} icon={"FaLanguage"} name={"user_email_input_placeholder"} value={language["user_email_input_placeholder"]}/>
							<Input col={3} title={"Web Sitesi Input Başlığı"} icon={"FaLanguage"} name={"user_website_input_title"} value={language["user_website_input_title"]}/>
							<Input col={3} title={"Web Sitesi Input Placeholder"} icon={"FaLanguage"} name={"user_website_input_placeholder"} value={language["user_website_input_placeholder"]}/>
							<Input col={3} title={"Şirket Pozisyonu Input Başlığı"} icon={"FaLanguage"} name={"company_position_input_title"} value={language["company_position_input_title"]}/>
							<Input col={3} title={"Şirket Pozisyonu Placeholder"} icon={"FaLanguage"} name={"company_position_input_placeholder"} value={language["company_position_input_placeholder"]}/>
							<Input col={3} title={"Şirket Adı Input Başlığı"} icon={"FaLanguage"} name={"company_name_input_title"} value={language["company_name_input_title"]}/>
							<Input col={3} title={"Şirket Adı Placeholder"} icon={"FaLanguage"} name={"company_name_input_placeholder"} value={language["company_name_input_placeholder"]}/>
							<Input col={3} title={"Şirket Adresi Input Başlığı"} icon={"FaLanguage"} name={"company_address_input_title"} value={language["company_address_input_title"]}/>
							<Input col={3} title={"Şirket Adresi Placeholder"} icon={"FaLanguage"} name={"company_address_input_placeholder"} value={language["company_address_input_placeholder"]}/>
							<Input col={3} title={"Şirket Telefonu Input Başlığı"} icon={"FaLanguage"} name={"company_phone_input_title"} value={language["company_phone_input_title"]}/>
							<Input col={3} title={"Şirket Telefonu Input Placeholder"} icon={"FaLanguage"} name={"company_phone_input_placeholder"} value={language["company_phone_input_placeholder"]}/>
							<Input col={3} title={"Şirket E-posta Input Başlığı"} icon={"FaLanguage"} name={"company_email_input_title"} value={language["company_email_input_title"]}/>
							<Input col={3} title={"Şirket E-posta Input Placeholder"} icon={"FaLanguage"} name={"company_email_input_placeholder"} value={language["company_email_input_placeholder"]}/>
							<Input col={3} title={"Şirket Web Sitesi Input Başlığı"} icon={"FaLanguage"} name={"company_website_input_title"} value={language["company_website_input_title"]}/>
							<Input col={3} title={"Şirket Web Sitesi Input Placeholder"} icon={"FaLanguage"} name={"company_website_input_placeholder"} value={language["company_website_input_placeholder"]}/>
							<Input col={6} title={"Profil Resmi Input Başlığı"} icon={"FaLanguage"} name={"profile_image_input_title"} value={language["profile_image_input_title"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Buton Ekle/Düzenle/Sil</legend>
						<Row>
							<Input col={6} title={"Ekle Menü Başlığı"} icon={"FaLanguage"} name={"add_button_menu_title"} value={language["add_button_menu_title"]}/>
							<Input col={6} title={"Düzenle Menü Başlığı"} icon={"FaLanguage"} name={"edit_button_menu_title"} value={language["edit_button_menu_title"]}/>
							<Input col={6} title={"Sil Menü Başlığı"} icon={"FaLanguage"} name={"delete_button_menu_title"} value={language["delete_button_menu_title"]}/>
							<Input col={6} title={"Sil Menü Yazısı"} icon={"FaLanguage"} name={"delete_button_menu_text"} value={language["delete_button_menu_text"]}/>
							<Input col={6} title={"Link Input Başlığı"} icon={"FaLanguage"} name={"add_button_link_input_title"} value={language["add_button_link_input_title"]}/>
							<Input col={6} title={"Link Input Placeholder"} icon={"FaLanguage"} name={"add_button_link_input_placeholder"} value={language["add_button_link_input_placeholder"]}/>
							<Input col={6} title={"İkon Input Başlığı"} icon={"FaLanguage"} name={"add_button_ikon_input_title"} value={language["add_button_ikon_input_title"]}/>
							<Input col={6} title={"İkon Input Placeholder"} icon={"FaLanguage"} name={"add_button_ikon_input_placeholder"} value={language["add_button_ikon_input_placeholder"]}/>
						</Row>
					</fieldset>

					<div className={"panelTitle"}>İçerik</div>
					<fieldset>
						<legend>İçerik Tipleri</legend>
						<Row>
							<Input col={3} title={"Buton İçeriği"} icon={"FaLanguage"} name={"component_type0"} value={language["component_type0"]}/>
							<Input col={3} title={"Resim İçeriği"} icon={"FaLanguage"} name={"component_type1"} value={language["component_type1"]}/>
							<Input col={3} title={"Video İçeriği"} icon={"FaLanguage"} name={"component_type2"} value={language["component_type2"]}/>
							<Input col={3} title={"Spotify İçeriği"} icon={"FaLanguage"} name={"component_type3"} value={language["component_type3"]}/>
							<Input col={3} title={"Wifi QR Kodu"} icon={"FaLanguage"} name={"component_type4"} value={language["component_type4"]}/>
							<Input col={3} title={"Rehbere Ekle Butonu"} icon={"FaLanguage"} name={"component_type5"} value={language["component_type5"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>İçerik Ekle</legend>
						<Row>
							<Input col={4} title={"Menü Başlığı"} icon={"FaLanguage"} name={"add_menu_title"} value={language["add_menu_title"]}/>
							<Input col={4} title={"İçerik Tipi Input Başlığı"} icon={"FaLanguage"} name={"component_type_input_title"} value={language["component_type_input_title"]}/>
							<Input col={4} title={"İçerik Tipi Input Placeholder"} icon={"FaLanguage"} name={"component_type_input_placeholder"} value={language["component_type_input_placeholder"]}/>
							<Input col={6} title={"Arka Plan Rengi Input Başlığı"} icon={"FaLanguage"} name={"add_menu_background_color_input_title"} value={language["add_menu_background_color_input_title"]}/>
							<Input col={6} title={"Yazı Rengi Input Başlığı"} icon={"FaLanguage"} name={"add_menu_color_input_title"} value={language["add_menu_color_input_title"]}/>
							<Input col={6} title={"Vazgeç Butonu"} icon={"FaLanguage"} name={"add_menu_cancel_button"} value={language["add_menu_cancel_button"]}/>
							<Input col={6} title={"Ekle Butonu"} icon={"FaLanguage"} name={"add_menu_add_button"} value={language["add_menu_add_button"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>İçerik Düzenle</legend>
						<Row>
							<Input col={4} title={"Menü Başlığı"} icon={"FaLanguage"} name={"edit_menu_title"} value={language["edit_menu_title"]}/>
							<Input col={4} title={"Arka Plan Rengi Input Başlığı"} icon={"FaLanguage"} name={"edit_menu_background_color_input_title"} value={language["edit_menu_background_color_input_title"]}/>
							<Input col={4} title={"Yazı Rengi Input Başlığı"} icon={"FaLanguage"} name={"edit_menu_color_input_title"} value={language["edit_menu_color_input_title"]}/>
							<Input col={4} title={"Sil Butonu"} icon={"FaLanguage"} name={"edit_menu_delete_button"} value={language["edit_menu_delete_button"]}/>
							<Input col={4} title={"Vazgeç Butonu"} icon={"FaLanguage"} name={"edit_menu_cancel_button"} value={language["edit_menu_cancel_button"]}/>
							<Input col={4} title={"Kaydet Butonu"} icon={"FaLanguage"} name={"edit_menu_save_button"} value={language["edit_menu_save_button"]}/>
							<Input col={6} title={"Sil Menü Başlığı"} icon={"FaLanguage"} name={"delete_component_menu_title"} value={language["delete_component_menu_title"]}/>
							<Input col={6} title={"Sil Menü Yazısı"} icon={"FaLanguage"} name={"delete_component_menu_text"} value={language["delete_component_menu_text"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Buton İçeriği</legend>
						<Row>
							<Input col={6} title={"Başlık Input Başlığı"} icon={"FaLanguage"} name={"component_type0_title_input_title"} value={language["component_type0_title_input_title"]}/>
							<Input col={6} title={"Başlık Input Placeholder"} icon={"FaLanguage"} name={"component_type0_title_input_placeholder"} value={language["component_type0_title_input_placeholder"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Resim İçeriği</legend>
						<Row>
							<Input col={3} title={"Başlık Input Başlığı"} icon={"FaLanguage"} name={"component_type1_title_input_title"} value={language["component_type1_title_input_title"]}/>
							<Input col={3} title={"Başlık Input Placeholder"} icon={"FaLanguage"} name={"component_type1_title_input_placeholder"} value={language["component_type1_title_input_placeholder"]}/>
							<Input col={3} title={"Resim Input Başlığı"} icon={"FaLanguage"} name={"component_type1_image_input_title"} value={language["component_type1_image_input_title"]}/>
							<Input col={3} title={"Açıklama Input Başlığı"} icon={"FaLanguage"} name={"component_type1_text1_input_title"} value={language["component_type1_text1_input_title"]}/>
							<Input col={3} title={"Açıklama Input Placeholder"} icon={"FaLanguage"} name={"component_type1_text1_input_placeholder"} value={language["component_type1_text1_input_placeholder"]}/>
							<Input col={3} title={"Alt Yazı Input Başlığı"} icon={"FaLanguage"} name={"component_type1_text2_input_title"} value={language["component_type1_text2_input_title"]}/>
							<Input col={3} title={"Alt Yazı Input Placeholder"} icon={"FaLanguage"} name={"component_type1_text2_input_placeholder"} value={language["component_type1_text2_input_placeholder"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Video İçeriği</legend>
						<Row>
							<Input col={3} title={"Video URL Input Başlığı"} icon={"FaLanguage"} name={"component_type2_url_input_title"} value={language["component_type2_url_input_title"]}/>
							<Input col={3} title={"Video URL Input Placeholder"} icon={"FaLanguage"} name={"component_type2_url_input_placeholder"} value={language["component_type2_url_input_placeholder"]}/>
							<Input col={3} title={"Başlık Input Başlığı"} icon={"FaLanguage"} name={"component_type2_title_input_title"} value={language["component_type2_title_input_title"]}/>
							<Input col={3} title={"Başlık Input Placeholder"} icon={"FaLanguage"} name={"component_type2_title_input_placeholder"} value={language["component_type2_title_input_placeholder"]}/>
							<Input col={3} title={"Açıklama Input Başlığı"} icon={"FaLanguage"} name={"component_type2_text1_input_title"} value={language["component_type2_text1_input_title"]}/>
							<Input col={3} title={"Açıklama Input Placeholder"} icon={"FaLanguage"} name={"component_type2_text1_input_placeholder"} value={language["component_type2_text1_input_placeholder"]}/>
							<Input col={3} title={"Alt Yazı Input Başlığı"} icon={"FaLanguage"} name={"component_type2_text2_input_title"} value={language["component_type2_text2_input_title"]}/>
							<Input col={3} title={"Alt Yazı Input Placeholder"} icon={"FaLanguage"} name={"component_type2_text2_input_placeholder"} value={language["component_type2_text2_input_placeholder"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Spotify İçeriği</legend>
						<Row>
							<Input col={3} title={"Spotify URL Input Başlığı"} icon={"FaLanguage"} name={"component_type3_url_input_title"} value={language["component_type3_url_input_title"]}/>
							<Input col={3} title={"Spotify URL Input Placeholder"} icon={"FaLanguage"} name={"component_type3_url_input_placeholder"} value={language["component_type3_url_input_placeholder"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Wifi QR Kodu</legend>
						<Row>
							<Input col={3} title={"Başlık Input Başlığı"} icon={"FaLanguage"} name={"component_type4_title_input_title"} value={language["component_type4_title_input_title"]}/>
							<Input col={3} title={"Başlık Input Placeholder"} icon={"FaLanguage"} name={"component_type4_title_input_placeholder"} value={language["component_type4_title_input_placeholder"]}/>
							<Input col={3} title={"Ağ Adı Input Başlığı"} icon={"FaLanguage"} name={"component_type4_network_name_input_title"} value={language["component_type4_network_name_input_title"]}/>
							<Input col={3} title={"Ağ Adı Input Placeholder"} icon={"FaLanguage"} name={"component_type4_network_name_input_placeholder"} value={language["component_type4_network_name_input_placeholder"]}/>
							<Input col={3} title={"Ağ Güvenliği Input Başlığı"} icon={"FaLanguage"} name={"component_type4_network_security_input_title"} value={language["component_type4_network_security_input_title"]}/>
							<Input col={3} title={"Güvenlik Yok"} icon={"FaLanguage"} name={"no_security"} value={language["no_security"]}/>
							<Input col={3} title={"Şifre Input Başlığı"} icon={"FaLanguage"} name={"component_type4_password_input_title"} value={language["component_type4_password_input_title"]}/>
							<Input col={3} title={"Şifre Input Placeholder"} icon={"FaLanguage"} name={"component_type4_password_input_placeholder"} value={language["component_type4_password_input_placeholder"]}/>
							<Input col={3} title={"Ağ Görünürlüğü Input Başlığı"} icon={"FaLanguage"} name={"component_type4_network_visibility_input_title"} value={language["component_type4_network_visibility_input_title"]}/>
							<Input col={3} title={"Ağ Görünürlüğü Input Placeholder"} icon={"FaLanguage"} name={"component_type4_network_visibility_input_placeholder"} value={language["component_type4_network_visibility_input_placeholder"]}/>
							<Input col={3} title={"Şifre Görünürlüğü Input Başlığı"} icon={"FaLanguage"} name={"component_type4_password_visibility_input_title"} value={language["component_type4_password_visibility_input_title"]}/>
							<Input col={3} title={"Şifre Görünürlüğü Placeholder"} icon={"FaLanguage"} name={"component_type4_password_visibility_input_placeholder"} value={language["component_type4_password_visibility_input_placeholder"]}/>
							<Input col={3} title={"Görünür Seçeneği"} icon={"FaLanguage"} name={"visible"} value={language["visible"]}/>
							<Input col={3} title={"Görünmez Seçeneği"} icon={"FaLanguage"} name={"invisible"} value={language["invisible"]}/>
						</Row>
					</fieldset>
					<fieldset>
						<legend>Rehbere Ekle</legend>
						<Row>
							<Input col={3} title={"Başlık Input Başlığı"} icon={"FaLanguage"} name={"component_type5_title_input_title"} value={language["component_type5_title_input_title"]}/>
							<Input col={3} title={"Başlık Input Placeholder"} icon={"FaLanguage"} name={"component_type5_title_input_placeholder"} value={language["component_type5_title_input_placeholder"]}/>
							<Input col={3} title={"Rehbere Ekle Butonu"} icon={"FaLanguage"} name={"component_type5_add_directory"} value={language["component_type5_add_directory"]}/>
							<Input col={3} title={"Button Arka Plan Rengi Input Başlığı"} icon={"FaLanguage"} name={"component_type5_button_background_color_input_title"} value={language["component_type5_button_background_color_input_title"]}/>
							<Input col={3} title={"Button Yazı Rengi Input Başlığı"} icon={"FaLanguage"} name={"component_type5_button_color_input_title"} value={language["component_type5_button_color_input_title"]}/>
						</Row>
					</fieldset>
					<Row>
						<Col md={12} className="button_area">
							<Button icon="AiOutlineSave" name="Kaydet"/>
						</Col>
					</Row>
				</form>
			</div>
		</div>
	)
}

export default Language;